import React, { useState } from 'react'
import {Box, Typography, Button, IconButton} from "@mui/material";
import { ReactComponent as EditIcon } from '../../img/edit_pencil.svg';
import { ReactComponent as AvatarIcon } from '../../img/avatar_temp.svg';

export default function UserPersonalInfo ()  {
    return(
        <Box sx={{ margin:'10px', borderRadius:'18px', padding:'20px', display:'flex', gap:'5px',  backgroundColor:' #EAF4FF66', flexDirection:'column'}}>
        {/* <Box component='img' src={localUserData?.picture} sx={{width:'100px', height:'100px', borderRadius:'50%'}}/> */}
        <Box sx={{display:'flex', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'column', gap:'5px', justifyContent:'center'}}>
            <Typography sx={{fontWeight:500, fontSize:'22px', color:'#1F3048',  lineHeight:'37px'}}>Person Information</Typography>
          </Box>
          <Box sx={{display:'flex', alignSelf:'center', borderRadius:'30px', background:'white', gap:'5px', height:'40px', padding:'0 10px' }}>
            <Typography sx={{fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px',alignSelf:'center'}}>Edit</Typography>
            <IconButton><EditIcon /></IconButton>
          </Box>
        </Box>

        <Box sx={{display:'flex', justifyContent:'space-between'}}>
          <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box sx={{ my:1.5}}>
              <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>First Name</Typography>
              <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>John</Typography>
            </Box>
            <Box sx={{ my:1.5}}>
              <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>Email Adress</Typography>
              <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>Johndoe@gmail.com</Typography>
            </Box>
            <Box sx={{ my:1.5}}>
              <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>Subscription Plan</Typography>
              <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>Premium</Typography>
            </Box>
          </Box>

          <Box sx={{display:'flex', flexDirection:'column'}}>
            <Box sx={{ my:1.5}}>
              <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>Last Name</Typography>
              <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>Doe</Typography>
            </Box>
            <Box sx={{ my:1.5}}>
              <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>Phone</Typography>
              <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>+1 234567890</Typography>
            </Box>
          </Box>
          <Box>
            
          </Box>
        </Box>
      
    </Box>
    )
}