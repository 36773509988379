import React, { useState, useEffect } from 'react'

import axios from "axios";

import DashboardUpcomingEventsAdd from "../a_component/Dashboard_upcomingeventsAdd"
import DashboardUpcomingEventsUpdate from "../a_component/Dashboard_upcomingeventsUpdate"


import { Button, Paper, Modal, TextField, Stack, Box, TableBody, TableCell, TableRow, TableHead, Table, TableContainer, Typography } from '@mui/material';


export default function DashboardUpcomingEvents() {



    const [geteventdata, setgeteventdata] = useState([])




    const token = localStorage.getItem("Admintoken")
    const headers = {
        Authorization: `Bearer ${token}`,

    };


    const geteventsdata = async () => {

        try {


            const response = await axios.get("http://localhost:9672/api/v1/upcomingevents", { headers })

            console.log(response.data.data);
            setgeteventdata(response.data.data)

        } catch (error) {
            console.log(error.message);
        }
    }

    // const geteventsdata = async () => {

    //     await axios.get("http://localhost:9672/api/v1/upcomingevents")
    //         .then(res => {
    //             console.log(res.data.data);
    //             setgeteventdata(res.data.data)
    //         })
    //         .catch(err => geteventdata(""))
    // }

    useEffect(() => {
        geteventsdata();

    }, [])

    const DeleteEvent = async (id) => {
        try {

            await axios.delete(`http://localhost:9672/api/v1/upcomingevents/${id}`,{ headers })
            geteventsdata();

        } catch (error) {
            console.log(error.message);
        }
    }




    return (


        <div>

            <DashboardUpcomingEventsAdd geteventsdata={geteventsdata} />
            {/* <DashboardUpcomingEventsUpdate/> */}
            <Box sx={{
                // boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                padding: "20px 20px 20px 20px ",
                borderRadius: "10px"
            }}>
                <Typography variant="h4" my={2}> Event List</Typography>

                <TableContainer component={Paper} sx={{ borderRadius: '10px', border: 'none', boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)" }}>
                    <Table sx={{ minWidth: 650, }} aria-label="simple table">

                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Id</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Title</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Subtitle</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Description</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Edit</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {geteventdata.map((itm) => {

                                return (
                                    <TableRow key={itm.id}>
                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.id}</TableCell>
                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.title}</TableCell>
                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.subtitle}</TableCell>
                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.description}</TableCell>

                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">
                                            <DashboardUpcomingEventsUpdate id={itm.id} items={itm} geteventsdata={geteventsdata} />
                                        </TableCell>
                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">
                                            <Button variant="contained" color="error" onClick={() => { DeleteEvent(itm.id) }}>Delete</Button>
                                        </TableCell>
                                    </TableRow>
                                )

                            })}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    )
}
