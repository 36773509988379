import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { Button, Modal, TextField, Input, Box, Stack } from '@mui/material';

export default function Dashboard_newsAdd({ getallnewsdata }) {




    const [addnewsdata, setaddnewsdata] = useState({ image: "", title: "", description: "" })

    const [upload, setupload] = useState(false)

    const [error, seterror] = useState(false)

    const [showaddnewsmodal, setshowaddnewsmodal] = useState(false)







    const handlechange = (e) => {
        const { name, value } = e.target
        setaddnewsdata({
            ...addnewsdata,
            [name]: value,
        })
        console.log(addnewsdata);

    }


    const submitnewsdata = async (e) => {


        if (!upload) {
            e.preventDefault()
            console.log("iucguitgb");
            seterror(true);
        } else {

            e.preventDefault()

            try {
                const formData = new FormData();
                formData.append('image', addnewsdata.image);
                formData.append('title', addnewsdata.title);
                formData.append('description', addnewsdata.description);


                const token = localStorage.getItem("Admintoken")
              


            await axios.post("http://localhost:9672/api/v1/news", formData,
                { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` }, })


            setaddnewsdata({ image: "", title: "", description: "" })
            setupload(false)
            setshowaddnewsmodal(false)
            getallnewsdata()
            // window.location = "/admin/Dashboard_news"

        } catch (error) {
            console.log(error.mesage);
        }
    }
}



const [imagePreview, setImagePreview] = useState(null);

const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setaddnewsdata({
        ...addnewsdata,
        image: selectedImage
    })

    console.log(addnewsdata);
    seterror(false);

};


const closeshowaddnewsmodal = () => {
    seterror(false);
    setshowaddnewsmodal(false)
    setaddnewsdata({ image: "", title: "", description: "" })
    // setupload(false)
}



useEffect(() => {

    if (addnewsdata.image instanceof File) {
        setupload(true)
    } else {
        setupload(false)
    }

}, [addnewsdata])


return (
    <Box sx={{ mt: 2, padding: '20px', display: "flex", justifyContent: "flex-end" }}>


        <Button variant='contained' color='success' onClick={() => { setshowaddnewsmodal(true) }}>Add News </Button>


        {/* <form onSubmit={submitnewsdata}> */}

        <Modal
            open={showaddnewsmodal}
            onClose={closeshowaddnewsmodal}
        // aria-labelledby="modal-title"
        // aria-describedby="modal-description"
        >
            {/* <Stack direction="row" justifyContent="center" > */}
            <Box component="form" onSubmit={submitnewsdata} sx={{
                // boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)",
                // padding: "20px",
                // borderRadius: "15px",
                // backgroundColor: "white"
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                minWidth: '300px'
            }}>
                <Button
                    sx={{ marginBottom: "20px" }}
                    variant="contained"
                    component="label"
                >{upload ? "uploaded" : "upload image"}

                    <Input type="file" name="image" onChange={handleImageChange} accept="image/*" hidden />
                </Button>


                {error && <p style={{ color: 'red' }}>Image should be uploaded</p>}
                {/* <input className='form-control' type="text" placeholder='Title' name='title' value={addnewsdata.title} onChange={handlechange} required /> */}
                {/* <input className='form-control' type="text" placeholder='Description' name='description' value={addnewsdata.description} onChange={handlechange} required /> */}



                <TextField sx={{ marginBottom: '20px' }}
                    label="Title"
                    variant="standard"
                    name='title'
                    value={addnewsdata.title}
                    onChange={handlechange}
                    fullWidth
                    required
                />


                <TextField sx={{ marginBottom: '20px' }}

                    label="Description"
                    variant="standard"
                    name="description"
                    value={addnewsdata.description}
                    onChange={handlechange}
                    fullWidth
                    required
                />
                <Stack direction="row" justifyContent="space-between">


                    <Button variant="contained" color="error" onClick={closeshowaddnewsmodal}>
                        Close
                    </Button>
                    <Button type='submit' variant="contained" color="success" >Add</Button>
                </Stack>
            </Box>
            {/* </Stack> */}
        </Modal>
        {/* </form> */}
    </Box>
)
}
