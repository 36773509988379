import React, { useState } from 'react'

import { Button, Paper, Modal, TextField, Stack, Box, TableBody, TableCell, TableRow, TableHead, Table, TableContainer, Typography } from '@mui/material';

import axios from 'axios';


export default function DashboardUpcomingEventsUpdate({ id, items, geteventsdata }) {




    // const [eventdata, seteventdata] = useState(items)

    const [editeventmodal, setediteventmodal] = useState(false)

    const [updateevent, setupdateevent] = useState(items)


    // console.log(eventdata);

    const handleupdatechange = (e) => {

        const { name, value } = e.target

        setupdateevent((prev) => {
            return (
                {
                    ...prev,
                    [name]: value
                }
            )
        })
        // console.log(updateevent);

    }

    const updateeventsave = async (id) => {

        try {

            const token = localStorage.getItem("Admintoken")
            const headers = {
                Authorization: `Bearer ${token}`,

            };

            await axios.put(`http://localhost:9672/api/v1/upcomingevents/${id}`,
                { "title": updateevent.title, "subtitle": updateevent.subtitle, "description": updateevent.description }, { headers })
            geteventsdata();
            setediteventmodal(false)

            console.log(id);
        } catch (error) {
            console.log(error.message);
        }

    }


    const editevent = (id) => {

        console.log(id);
        // console.log(eventdata);
        setediteventmodal(true)
    }

    return (<>

        <Button variant="contained" color="warning" onClick={() => { editevent(id) }}>Edit</Button>

        <Modal
            open={editeventmodal}
            onClose={() => { setediteventmodal(false) }}>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                minWidth: '300px'
            }}>
                <h2 id="modal-title ">Edit</h2>

                <TextField sx={{ marginBottom: '20px' }}
                    variant="standard"
                    label="Title"
                    name="title"
                    value={updateevent.title}
                    onChange={handleupdatechange}
                    fullWidth
                />
                <TextField sx={{ marginBottom: '20px' }}
                    variant="standard"
                    label="Subtitle"
                    name="subtitle"
                    value={updateevent.subtitle}
                    onChange={handleupdatechange}
                    fullWidth
                />
                <TextField sx={{ marginBottom: '20px' }}
                    variant="standard"
                    label="Description"
                    name="description"
                    value={updateevent.description}
                    onChange={handleupdatechange}
                    fullWidth
                />

                <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Button variant="contained" color="error" onClick={() => { setediteventmodal(false) }}>Close</Button>
                    <Button variant="contained" color="success" onClick={() => { updateeventsave(id) }}>Save</Button>

                </Stack>
            </Box>

        </Modal>


    </>
    )
}
