import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { TableCell, TableRow, tableCellClasses } from '@mui/material'
import styled from "@emotion/styled";


let defaultTheme = createTheme({
    typography: {
        fontFamily: 'Jost, sans-serif',
        h1: {
            fontSize: 60,
            fontWeight: 500,
        },
        h1_A: {
            fontSize: 34,
            fontWeight: 500,
            fontFamily: 'Jost, sans-serif',
        },
        h2: {
            fontSize: 28,
            fontWeight: 500,
        },
        h3: {
            fontSize: 22,
            fontWeight: 500,
            letterSpacing: .4

        },
        h4: {
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: .3
        },
        h5: {
            fontSize: 16,
            fontWeight: 500,
            letterSpacing: .3
        },
        h5_1: {
            fontSize: 17,
            fontWeight: 500,
            fontFamily: 'Jost, sans-serif',
            letterSpacing: .2
        },
        h5_2: {
            fontSize: 14,
            fontWeight: 600,
            fontFamily: 'Jost, sans-serif',
            letterSpacing: .3
        },
        h6: {
            fontSize: 13,
            fontWeight: 500,
            letterSpacing: .3
        },
        h7: {
            fontSize: 12,
            fontWeight: 500,
            letterSpacing: .4,
            fontFamily: 'Jost, sans-serif',
        },
        subtitle1: {
            fontSize: 17,
            lineHeight: 1.25
        }
    },
    status: {
        danger: '#e53e3e'
    },
    palette: {
        // primary: {
        //     main: '#FFFFFF',
        //     lightText: '#CACACA',
        //     DarkText: '#3F7DBC'
        // },
        // secondary: {
        //     main: '#F0F7FD',
        //     lightColor: '#DCEFD2'
        // },
        // Tertiary: '#93B0C8',
        // Errors: '#E84C3D',
        // Bg_color: '#F0F7FD',
        // Title_Text_color: '#CACACA'
        primary: {
            main: '#3F7DBC',
        },
        Secondary: {
            main: '#50AD20'
        },
        Tertiary: '#93B0C8',
        Errors: '#E84C3D',
        Bg_color: '#F0F7FD',
        Title_Text_color: '#CACACA',
        White_color: '#FFFFFF',
        Heading_color: '#1F3048',
        Grey: '#8A8C8F'

    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: '8px',
                    height: "41px",
                    padding: '10px',
                    fontWeight: "600",
                    "&:hover": {
                        boxShadow: 'none',
                        // outline: 'none'
                    }
                },
                Standard: {
                    width: "fit-content",
                    border: 'none',
                    backgroundColor: '#3F7DBC',
                    color: '#FFFFFF',
                    fontSize: '15px',
                    fontWeight: 500,
                    letterSpacing: .3,
                    padding: '10px 20px',
                    "&:hover": {
                        backgroundColor: '#3F7DBC',
                        border: 'none',
                    }
                },
                outlined: {
                    width: "fit-content",
                    border: '1px solid #CFCFCF',
                    // backgroundColor: '',
                    color: '#111111',
                    fontSize: '15px',
                    fontWeight: 500,
                    letterSpacing: .3,
                    "&:hover": {
                        backgroundColor: 'none',
                    }
                }

            }
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    minWidth: 'fit-content',
                    overflowX: 'auto',
                    "::-webkit-scrollbar": { display: "none" },
                }
            }
        }
    }
});

defaultTheme = responsiveFontSizes(defaultTheme)



export const StyledTableCell = styled(TableCell)({
    // color: '#000000',
    textAlign: 'left',
    // wordBreak: "break-word",
    whiteSpace: 'nowrap',
    border: 'none',
    width: 'fit-content',
    // '&:nth-of-type(1)': { paddingLeft: '30px !important' },

    [`&.${tableCellClasses.head}`]: {
        fontSize: '15px',
        fontWeight: 500,
        padding: '20px 15px 7px 15px',
        // backgroundColor: '#F9FAFB',
        // padding: '0px 13px 0px 13px',
        // height: '46px'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: '15px',
        fontWeight: 500,
        cursor: 'pointer',
        padding: '27px 15px 27px 15px',
        // padding: '13px',
        // height: '50px',
    },
})

export const StyledTableRow = styled(TableRow)({
    // height: '60px',
    // padding: '40px',

})

export default defaultTheme;