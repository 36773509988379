import React, { useState, useRef, createElement } from 'react'
import {Box, Typography, IconButton,Popper,Fade, Paper } from "@mui/material";
import CopyIcon from '../../img/copy.png';

export default function WalletAddress ()  {
    const [open, setOpen] = useState(false);
    const walletAddress = "3K9CKsePi...Df5NfQh7iK";
    const anchorRef = useRef(null);
    
    const copyToClipboard = async() => {
        try {
            await navigator.clipboard.writeText(walletAddress);
            setOpen(true);
            setTimeout(() => {
                setOpen(false)
            },3000)
        } catch(err) {
            // alert('Failed to copy text: ', err);
        }
        
    }
    return(
        <Box sx={{ margin:'10px', borderRadius:'18px', padding:'20px', display:'flex', gap:'5px',  backgroundColor:' #EAF4FF66', flexDirection:'column'}}>
        {/* <Box component='img' src={localUserData?.picture} sx={{width:'100px', height:'100px', borderRadius:'50%'}}/> */}
          <Box sx={{display:'flex', flexDirection:'column', gap:'5px', justifyContent:'center'}}>
            <Typography sx={{fontWeight:400, fontSize:'18px', color:'#1F3048',  lineHeight:'29px'}}>Wallet address</Typography>
          </Box>

        <Box sx={{display:'flex', justifyContent:'space-between', gap:'10px',}}>
            <Typography sx={{fontWeight:400, fontSize:'18px', color:'#000000',  lineHeight:'30px'}}>{walletAddress}</Typography>
            <IconButton ref={anchorRef}  onClick={copyToClipboard}><Box component="img" src={CopyIcon} sx={{width:'18px', height:'20px'}}/></IconButton>
            <Popper style={{zIndex:100000}} open={open} anchorEl={anchorRef.current} placement="bottom" transition>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} style={{zIndex:100000}} >
                        <Paper sx={{backgroundColor:'black', color:'white',padding:'5px', zIndex:100000}}>
                            <Typography variant="body1">Copied to clipboard</Typography>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </Box>
      
    </Box>
    )
}