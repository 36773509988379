import React from 'react'
import MessageIcon from '../../img/MessageIcon.png';
import { Box, Typography, IconButton, Stack, Pagination, PaginationItem, Radio } from '@mui/material';
import { ReactComponent as MarkAsReadIcon } from '../../img/markAsReadIcon.svg';
import { ReactComponent as MarkAsUnread } from '../../img/markAsUnread.svg';
import { ReactComponent as MarkAsReadDisable } from '../../img/markAsReadDisable.svg';
import { ReactComponent as MarkAsUnreadEnable } from '../../img/markAsUnreadEnable.svg';
import { ReactComponent as RemoveNotifitcationIcon } from '../../img/removeNotifitcationIcon.svg';

import { ReactComponent as PaginationForwardIcon } from '../../img/PaginationForwardIcon.svg';
import { ReactComponent as CorrectCircleIcon } from '../../img/CorrectCircleIcon.svg';
import { ReactComponent as CorrectCircleDisableIcon } from '../../img/CorrectCircleDisableIcon.svg';


export default function General() {
    const generalData = [
        { id: 1, icon: MessageIcon, boldText: 'John Doe', lightText: 'has joined from your invitation and you will get your referral points.', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 2, icon: MessageIcon, boldText: 'You have turned off the email updates.', lightText: '', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 3, icon: MessageIcon, boldText: 'Your password has been successfully changed.', lightText: '', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 4, icon: MessageIcon, boldText: '', lightText: 'This notification has been read by you and now you can delete it.', time: '1 mins ago', type: 'New', markAsRead: false, markAsUnRead: true },
        { id: 5, icon: MessageIcon, boldText: 'John Doe', lightText: 'has joined from your invitation and you will get your referral points.', time: '1 mins ago', type: 'New', markAsRead: false, markAsUnRead: true },
    ]
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <Box sx={{ backgroundColor: 'white', padding: '30px 40px' }}>
                <Typography variant='h3'>Emails from US</Typography>
                <Typography mt={1} variant='h5' color='Title_Text_color'>Receive the latest news, market trends and every update from us.</Typography>
                <Stack mt={3} rowGap={4}>
                    <Stack direction='row' columnGap={2.5} alignItems='center'>
                        <CorrectCircleIcon />
                        <Typography variant='h4' letterSpacing='.8px'>News & Updates</Typography>
                    </Stack>
                    <Stack direction='row' columnGap={2.5} alignItems='center'>
                        <CorrectCircleIcon />
                        <Typography variant='h4' letterSpacing='.8px'>Tips & Tutorials</Typography>
                    </Stack>
                    <Stack direction='row' columnGap={2.5} alignItems='center'>
                        <CorrectCircleDisableIcon />
                        <Typography variant='h4' letterSpacing='.8px' color='Title_Text_color'>Product Research</Typography>
                    </Stack>
                    <Stack direction='row' columnGap={2.5} alignItems='center'>
                        <CorrectCircleDisableIcon />
                        <Typography variant='h4' letterSpacing='.8px' color='Title_Text_color'>Market Trends</Typography>
                    </Stack>
                </Stack>
            </Box>

            <Box sx={{ backgroundColor: 'white', padding: '30px 50px' }}>
                <Typography variant='h3'>Reminders</Typography>
                <Typography mt={1} variant='h5' color='Title_Text_color'>These are the notifications about the updates that you might’ve missed.</Typography>
                <Stack mt={3}>
                    <Stack direction='row' columnGap={2} alignItems='center'>
                        <Radio checked={true} disabled sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />
                        <Typography variant='h4' letterSpacing='.7px'>Do Not Notify Me</Typography>
                    </Stack>
                    <Stack direction='row' columnGap={2}>
                        <Radio checked={true} sx={{ color: '#50AD20', '&.Mui-checked': { color: '#50AD20', }, '& .MuiSvgIcon-root': { fontSize: 28, }, }} />
                        <Stack mt={3}>
                            <Typography variant='h4' letterSpacing='.7px' color='#50AD20'>Important Updates Only</Typography>
                            <Typography mt={1} variant='h5_2' color='Title_Text_color'>Only notify me if the reminder is tagged as important.</Typography>
                        </Stack>
                    </Stack>
                    <Stack direction='row' columnGap={2} >
                        <Radio checked={true} disabled sx={{ '& .MuiSvgIcon-root': { fontSize: 28, }, }} />
                        <Stack mt={4}>
                            <Typography variant='h4' letterSpacing='.7px'>All Reminder</Typography>
                            <Typography mt={1} variant='h5_2' color='Title_Text_color'>Notify me for all the reminders.</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Box>

            <Box sx={{ backgroundColor: 'white', padding: '30px 50px 50px 50px', borderRadius: "0 0 20px 20px" }}>
                <Typography variant='h3'>More Activity About You</Typography>
                <Typography mt={1} variant='h5' color='Title_Text_color'>These are the notification about the subscriptions you buy, portfolio you add and update every news or join any event.</Typography>
                <Stack mt={3} rowGap={3}>
                    <Stack direction='row' columnGap={2} alignItems='center'>
                        <CorrectCircleIcon />
                        <Typography variant='h4' letterSpacing='.7px'>Do Not Notify Me</Typography>
                    </Stack>
                </Stack>
            </Box>
        </Box >
    )
}
