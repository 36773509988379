import React from 'react'
import { Box, Stack, Table, TableHead, TableContainer, TableBody, TableCell, TableRow, tableCellClasses, PaginationItem, Pagination } from "@mui/material";
import { StyledTableRow, StyledTableCell } from "../../Theme"
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { borderRadius } from '@mui/system';
import { MdAdd } from "react-icons/md";
import { FaMinus } from "react-icons/fa6";
import { ReactComponent as DepositIcon } from '../../img/transactionDeposit.svg';
import { ReactComponent as WithdrawIcon } from '../../img/TransactionWithdraw.svg';
import { ReactComponent as PaginationForwardIcon } from '../../img/PaginationForwardIcon.svg';
import { ReactComponent as PaginationBackArrowIcon } from '../../img/PaginationBackArrow.svg';
import ChooseYourMethod from './ChooseYourMethod';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const columns = [
    { id: '1', label: 'Date', minWidth: 170 },
    { id: '2', label: 'Amount', minWidth: 170 },
    { id: '3', label: 'Type', minWidth: 170 },
];

const tableData = [
    {
        id: 1,
        date: '27 May 2023',
        amount: "+ $230.45",
        type: 'Bank Transfer',
    },
    {
        id: 2,
        date: '27 May 2023',
        amount: "+ $230.45",
        type: 'Wallet Transfer',
    },
    {
        id: 3,
        date: '27 May 2023',
        amount: "+ $230.45",
        type: 'Wallet Transfer',
    },
    {
        id: 4,
        date: '27 May 2023',
        amount: "+ $230.45",
        type: 'Bank Transfer',
    },
    {
        id: 5,
        date: '27 May 2023',
        amount: "+ $230.45",
        type: 'Wallet Transfer',
    },
    {
        id: 6,
        date: '27 May 2023',
        amount: "+ $230.45",
        type: 'Wallet Transfer',
    },

]


export default function Transactions() {
    return (
        <>
            <Box backgroundColor='#F0F7FD' height='auto'>
                <Box columnGap={4} rowGap={3} sx={{
                    backgroundColor: 'white',
                    padding: {
                        sm: '20px 30px 35px 30px',
                        xs: '20px 15px 35px 15px'
                    },
                    borderTopLeftRadius: "18px",
                    borderTopRightRadius: '18px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: { xs: 'column', md: 'row' }
                }}>
                    <Box rowGap={2} sx={{ display: 'flex', flexDirection: 'column', }}>
                        <Typography variant='h2' >Transactions</Typography>
                        <Typography variant='h6' color='Grey'>In The Axie Infinity, game designers can create custom 3D NFTs that can be used</Typography>
                    </Box>

                    <Box columnGap={2} rowGap={2} sx={{ display: 'flex', flexDirection: { md: 'column', lg: 'row' } }}>
                        {/* <Button endIcon={<MdAdd /> } sx={{width:'140px', fontSize:'14px', textTransform:'none', color:'white', backgroundColor:'#3F7DBC', borderRadius:'8px', '&:hover': {
                                backgroundColor:'#3F7DBC'
                            }}}>Add Deposit</Button> */}
                        <ChooseYourMethod />
                        <Button endIcon={<FaMinus />} sx={{ width: '140px' }} variant='outlined' >Withdraw</Button>
                    </Box>
                </Box>

                <Box component='div' mt={.5} >
                    <TableContainer sx={{ backgroundColor: 'White_color', borderRadius: '0 0 10px 10px' }}>
                        <Table aria-label="simple table">
                            <TableHead >
                                <StyledTableRow sx={{ '.MuiTableCell-root': { color: '#BCC1C8', textAlign: 'center',fontSize:"18px" } }}>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Type</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.map((item, index) => (
                                    <StyledTableRow sx={{ '.MuiTableCell-root': { textAlign: 'center' } }}>
                                        <StyledTableCell >
                                            <Box columnGap={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {item.type == 'Bank Transfer' ? (<DepositIcon />) : (<WithdrawIcon />)}{item.date}
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ color: item.type == 'Bank Transfer' ? '#50AD20' : "Errors" }}>{item.amount}</StyledTableCell>
                                        <StyledTableCell >{item.type}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Stack mt={4} direction='row' justifyContent='center' spacing={2}>
                    <Pagination
                        count={5}
                        sx={{ '.MuiPaginationItem-circular': { backgroundColor: 'white' }, '.Mui-selected': { backgroundColor: '#3F7DBC !important', color: 'white' }, '.Mui-selected:hover': { backgroundColor: '#3F7DBC', color: 'white' } }}
                        renderItem={(item) => (
                            <PaginationItem slots={{ previous: PaginationBackArrowIcon, next: PaginationForwardIcon }}  {...item}
                            />)}
                    />
                </Stack>

                {/* <Box mt={.5} sx={{
                    backgroundColor: 'white',
                    borderBottomLeftRadius: '18px',
                    borderBottomRightRadius: '18px',
                    padding: { sm: '20px 45px 35px 45px', xs: '20px 0px 20px 0px' },
                    position: "relative",
                    marginBottom: '30px',
                    position: 'relative'


                }}>

                    <Box container sx={{
                    }}>
                        <Table sx={{
                            [`& .${tableCellClasses.root}`]: {
                                borderBottom: "none",
                            }
                        }}>
                            <TableHead sx={{ color: '#CACACA', fontWeight: '500', fontSize: '20px', }}>
                                <TableRow sx={{ border: 'none', outline: 'none' }}>
                                    <TableCell sx={{ paddingLeft: '72px' }}><Typography sx={{ color: '#CACACA', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>Date</Typography></TableCell>
                                    <TableCell><Typography sx={{ color: '#CACACA', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>Amount</Typography></TableCell>
                                    <TableCell sx={{ paddingLeft: '30px' }}><Typography sx={{ color: '#CACACA', fontWeight: '500', fontSize: '20px', lineHeight: '30px' }}>Type</Typography></TableCell>
                                </TableRow>

                            </TableHead>
                            <TableBody >
                                {tableData.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell sx={{ display: 'flex', gap: '15px', alignItems: 'center' }}>{item.type == 'Bank Transfer' ? (<DepositIcon />) : (<WithdrawIcon />)}<Typography sx={{ color: '#1F3048', fontWeight: '700', fontSize: '15px', lineHeight: '21px', letterSpacing: '2%' }}>{item.date}</Typography></TableCell>
                                        <TableCell><Typography sx={{ color: item.type == 'Bank Transfer' ? '#50AD20' : '#E84C3D', fontWeight: '700', fontSize: '15px', lineHeight: '21px', letterSpacing: '2%' }}>{item.amount}</Typography></TableCell>
                                        <TableCell><Typography sx={{ color: '#1F3048', fontWeight: '700', fontSize: '15px', lineHeight: '21px', letterSpacing: '2%' }}>{item.type}</Typography></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </Box>
                </Box> */}
            </Box>
        </>
    )
}
