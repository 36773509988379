import { Box, Button, Input, InputLabel, Link, TextField, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ageEighteenValidator, alphaNumericUnderscoreValidator, alphabetsWhiteSpaceValidator, emailValidator, numberOnChangeValidator, passwordValidator } from '../../utils/validation';
import { webAuth } from '../../Auth0/WebAuth';
import { ApiService } from '../../API/AxiosInstance';
import { useNavigate } from 'react-router';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from 'react-redux';
import { SetSnackBar } from '../../redux/Global/GlobalAction';

const StyledTextField = styled(TextField)((props) => ({
    width: "100%",
    borderRadius: "6px",
    outline: 'none',
    "& .MuiOutlinedInput-root": {
        height: "35px",
        fontSize: "1rem !important",
        "&.Mui-focused fieldset": {
            border: '1.6px solid black',
        }
    },
    '& fieldset': {
        border: "1.6px solid #357EC7"
    },

}));

const StyledInputLabel = styled(InputLabel)({
    fontSize: "1rem",
    margin: "0 0 2.5px 0",
    fontWeight: 500,
    color: "black"

});

const StyledErrorText = styled(Typography)({
    color: '#ff0000',
    fontSize: "0.85rem",
    margin: "4px 0"
})

const SignUp = () => {

    const navigate = useNavigate();
    const { loginWithRedirect, user, getIdTokenClaims, isAuthenticated } = useAuth0();
    const dispatch = useDispatch();

    const validConfigs = {
        firstName: alphabetsWhiteSpaceValidator,
        lastName: alphabetsWhiteSpaceValidator,
        userName: alphaNumericUnderscoreValidator,
        eMail: emailValidator,
        mobileNo: (mobileNo) => { if (mobileNo.length === 10) { return true } else { return false } },
        password: passwordValidator,
        dob: ageEighteenValidator,
    }

    const initalState = { firstName: "", lastName: "", userName: "", eMail: "", mobileNo: "", password: "", dob: "" }

    const [signUpData, setSignUpData] = useState(initalState);

    const [errorSignUp, setErrorSignUp] = useState(initalState);

    const handleInputChange = e => {
        const { name, value } = e.target;

        if (name === "mobileNo" && numberOnChangeValidator(value) === false) {
            return;
        }

        setErrorSignUp(prevValue => { return { ...prevValue, [name]: "" } })
        setSignUpData(prevValue => { return { ...prevValue, [name]: value } })
    }

    const checkFormIsValid = () => {
        let currentState = signUpData;
        let isValid = true;

        for (const key in currentState) {

            if (currentState[key] === "") {
                setErrorSignUp(prevValue => { return { ...prevValue, [key]: "Should not be empty!" } });
                isValid = false;
            }
            else if (validConfigs[key](currentState[key]) === false) {
                setErrorSignUp(prevValue => { return { ...prevValue, [key]: `Invalid Input` } });
                isValid = false;
            } else {
                setErrorSignUp(prevValue => { return { ...prevValue, [key]: "" } });
            }
        }

        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const isValid = checkFormIsValid();
        if (isValid) {
            console.log('No Error');
            webAuth.signup({
                connection: 'Username-Password-Authentication',
                email: signUpData.eMail,
                password: signUpData.password,
                username: signUpData.userName,
            }, function (err) {
                if (err) {
                    return alert('Something went wrong: ' + err.message)
                } else {
                    ApiService().post("/customer", {
                        firstName: signUpData.firstName,
                        lastName: signUpData.lastName,
                        dob: signUpData.dob,
                        email: signUpData.eMail,
                        userName: signUpData.userName,
                        mobileNumber: signUpData.mobileNo,
                    }).then(res => {
                        if (res.status === 201) {
                            setSignUpData(initalState);
                            loginWithRedirect();
                        }
                    }).catch(err => {
                        if (err) {
                            console.log("Error in customer api :", err);
                            //negative should be handled if not added in db
                        }
                    })
                }
            });
        } else {
            console.log("Error")
        }


    }


    return (
        <div style={{ zIndez: 3 }}>
            <Box sx={{ width: "100%", background: "#5CB3FF", display: 'flex', justifyContent: "center", alignItems: "center", height: "auto", minHeight: "100vh", padding: "25px 0", zIndex: 10 }}>
                <Box sx={{ width: { xs: "280px", sm: "350px", md: "450px" }, padding: { xs: "10px", sm: "15px", md: "40px" }, border: "1px solid black", borderRadius: "8px", background: "white", top: "25px", }}>
                    <form onSubmit={e => handleSubmit(e)} autoComplete='off'>
                        <Box>
                            < Typography sx={{ textAlign: "center", fontSize: "1.5rem", fontWeight: 600 }}>Sign Up</Typography>
                        </Box>
                        <div style={{ display: "flex", flexDirection: "column", placeContent: "space-evenly", }} >

                            <Box>
                                <StyledInputLabel>First Name</StyledInputLabel>
                                <StyledTextField type="text" name="firstName" onChange={e => handleInputChange(e)} value={signUpData.firstName} error={Boolean(errorSignUp.firstName)}></StyledTextField>
                                <StyledErrorText>{errorSignUp.firstName}</StyledErrorText>
                            </Box>
                            <Box>
                                <StyledInputLabel>Last Name</StyledInputLabel>
                                <StyledTextField type="text" name="lastName" onChange={e => handleInputChange(e)} value={signUpData.lastName} error={Boolean(errorSignUp.lastName)}></StyledTextField>
                                <StyledErrorText>{errorSignUp.lastName}</StyledErrorText>
                            </Box>
                            <Box>
                                <StyledInputLabel>User Name</StyledInputLabel>
                                <StyledTextField type="text" name="userName" onChange={e => handleInputChange(e)} value={signUpData.userName} error={Boolean(errorSignUp.userName)} ></StyledTextField>
                                <StyledErrorText>{errorSignUp.userName}</StyledErrorText>
                            </Box>
                            <Box>
                                <StyledInputLabel>Date of birth</StyledInputLabel>
                                <StyledTextField type="date" name="dob" onChange={e => handleInputChange(e)} value={signUpData.dob} error={Boolean(errorSignUp.dob)}></StyledTextField>
                                <StyledErrorText>{errorSignUp.dob}</StyledErrorText>
                            </Box>
                            <Box>
                                <StyledInputLabel>E-Mail</StyledInputLabel>
                                <StyledTextField type="text" name="eMail" onChange={e => handleInputChange(e)} value={signUpData.eMail} error={Boolean(errorSignUp.eMail)}></StyledTextField>
                                <StyledErrorText>{errorSignUp.eMail}</StyledErrorText>
                            </Box>
                            <Box>
                                <StyledInputLabel>Mobile Number</StyledInputLabel>
                                <StyledTextField type="text" name="mobileNo" onChange={e => handleInputChange(e)} value={signUpData.mobileNo} error={Boolean(errorSignUp.mobileNo)} ></StyledTextField>
                                <StyledErrorText>{errorSignUp.mobileNo}</StyledErrorText>
                            </Box>
                            <Box>
                                <StyledInputLabel>Password</StyledInputLabel>
                                <StyledTextField type="password" name="password" onChange={e => handleInputChange(e)} value={signUpData.password} error={Boolean(errorSignUp.password)} ></StyledTextField>
                                <Typography variant='h6' sx={{ opacity: 0.8, marginTop: "1rem", fontSize: { xs: "0.8rem", sm: "1rem" } }} >Must contain atleast a special character (_*-@) and a number </Typography>
                                <StyledErrorText>{errorSignUp.password}</StyledErrorText>
                                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                                    <Typography sx={{ color: "blue", "&:hover": { textDecoration: "underline", cursor: "pointer" } }} onClick={() => {
                                        loginWithRedirect();
                                    }
                                    }>Already a user? Login
                                    </Typography>
                                </Box>
                            </Box>

                        </div>


                        <Button type='submit' variant='contained' color='Bg_color' fullWidth sx={{ fontSize: "1rem", textTransform: "none", mt: "15px" }}>Sign Up</Button>


                    </form>
                </Box >
            </Box >
        </div >
    )
}

export default SignUp