import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ApiService } from '../API/AxiosInstance';

import './Dashboard.css';

import ApexCharts from 'apexcharts';

import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import { Box, Accordion, AccordionSummary, AccordionDetails, useMediaQuery, useTheme } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ReactComponent as NotificationIcon } from '../img/icon_bell.svg';
import { ReactComponent as AvatarIcon } from '../img/avatar_temp.svg';
import { ReactComponent as ChcekList } from '../img/checklist.svg';
import { ReactComponent as PlusIcon } from '../img/PlusIcon.svg';
import { ReactComponent as MinusIcon } from '../img/MinusIcon.svg';

import { ReactComponent as DownArrow } from '../img/DownArrow.svg';

import { ReactComponent as DollarSymbolIcon } from '../img/DollarSymbol.svg';
import { ReactComponent as TradeTogetherLogo } from '../img/logo.svg';
import { ReactComponent as ChartTemp } from '../img/chart.svg';

import News1 from '../img/dashboard/news1.jpg';
import News2 from '../img/dashboard/news2.jpeg';
import News3 from '../img/dashboard/news3.jpeg';

// REACT ICONS

import { PiNotepad } from 'react-icons/pi';
import { AiFillAppstore, AiOutlineDollarCircle } from 'react-icons/ai';
import { TbUsers } from 'react-icons/tb';
import { IoSettingsOutline } from 'react-icons/io5';
import { BsBoxSeam } from 'react-icons/bs';
import { FiBarChart2, FiMail } from 'react-icons/fi';
import { LuMail } from 'react-icons/lu';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { mainListItems, secondaryListItems } from '../components/listItems';
import { borderRadius, display, fontSize, height, padding } from '@mui/system';
import { ListItemButton, ListItemIcon } from '@mui/material';

//News data
const NewsData = [{
  id: 1,
  img: News1,
  title: "Dollar Today",
  des: "Loreum ipsum text here"
},
{
  id: 2,
  img: News2,
  title: "Welcome",
  des: "Loreum ipsum text here"
},
{
  id: 3,
  img: News3,
  title: "New Crypto..",
  des: "Loreum ipsum text here"
},
{
  id: 4,
  img: News3,
  title: "New Crypto..",
  des: "Loreum ipsum text here"
}
]

const UpcomingEventsData = [
  {
    id: 1,
    title1: 'SAND',
    title2: 'The Sandbox',
    des: 'In The Sandbox, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.',
  },
  {
    id: 2,
    title1: 'AXS',
    title2: 'Axie Infinity',
    des: 'In The Axie Infinity, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.',
  },
];

const RecentTTransactionData = [
  {
    id: 1,
    status: true,
    date: '27 May 2023',
    price: '230.45',
  },
  {
    id: 2,
    status: false,
    date: '27 May 2023',
    price: '230.45',
  },
  {
    id: 3,
    status: true,
    date: '27 May 2023',
    price: '230.45',
  },
  {
    id: 4,
    status: false,
    date: '27 May 2023',
    price: '230.45',
  },
  {
    id: 5,
    status: true,
    date: '27 May 2023',
    price: '230.45',
  },

];

const AccordianStyle = { boxShadow: 'none', margin: '0px !important', }
const AccordianSummaryStyle = {
  '.MuiAccordionSummary-content': {
    marginTop: ' 0px !important',
  },
  padding: '0px',
  ' .MuiAccordionSummary-expandIconWrapper': {
    position: 'absolute',
    top: '-35px',
    right: 0,
  },
}

const expandIconStyle = {
  width: '45px',
  height: '45px',
  backgroundColor: 'white !important',
  boxShadow: "0 2px 1px -1px rgba(0,0,0,0.2) !important",
  display: { xs: 'block', sm: 'none' }
}

export default function Dashboard() {


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const expandInitialState = { rewards: true, news: true, upcoming_events: true, transaction: true }
  const [expand, setExpand] = useState(expandInitialState)
  useEffect(() => {
    setExpand(expandInitialState)
  }, [isSmallScreen])

  console.log(expand.rewards);
  const [NewsDatas, setNewsDatas] = useState([]);

  const [sidebaropen, setsidebarOpen] = React.useState(true);
  const toggleDrawer = () => {
    setsidebarOpen(!sidebaropen);
  };

  const getNewsDatas = async () => {
    try {
      // const response = await axios.get("http://localhost:9672/api/v1/news")
      const response = await ApiService().get('/news');
      setNewsDatas(response.data.data);
      console.log('res', response.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getNewsDatas();
  }, []);

  useEffect(() => {
    // Create the chart in the useEffect hook
    const options = {
      chart: {
        type: 'area',
        height: '400px',
        width: '100%',
        toolbar: {
          show: false,
        },
      },
      padding: {
        top: '100px', // Add the desired space between Y-axis categories and the chart
        bottom: '100px', // Add the desired space between the chart and the X-axis
      },
      dataLabels: {
        enabled: false,
      },

      yaxis: {
        type: 'category',
        categories: ['10000', '20000', '30000', '40000', '50000'],
        labels: {
          style: {
            fontSize: '13px',
            fontWeight: '500',
          },
        },
      },

      series: [
        {
          data: [
            45000, 40000, 45000, 29000, 28800, 27000, 32000, 33500, 33000,
            44999, 44878, 42000, 46000, 41000, 43000, 28000, 28555, 27888,
            28344, 22000, 21877, 16000, 32984, 39000, 45000, 39885, 38009,
            38009, 38009,
          ],
        },
      ],
      colors: ['#3F7DBC'],

      fill: {
        type: 'gradient',
        colors: ['#3F7DBC'],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },

      xaxis: {
        categories: [
          'MAR 22',
          '',
          '',
          'APR 22',
          '',
          '',
          'MAY 22',
          '',
          '',
          'JUN 22',
          '',
          '',
          'JUL 22',
          '',
          '',
          'AUG 22',
          '',
          '',
          'SEP 22',
          '',
          '',
          'OCT 22',
          '',
          '',
          'NOV 22',
          '',
          '',
          'DEC 22',
        ],
        labels: {
          style: {
            fontSize: '13px',
            fontWeight: '500',
          },
        },
      },
    };

    const chart = new ApexCharts(document.querySelector('#chart'), options);
    chart.render();

    // Cleanup the chart when the component is unmounted
    return () => {
      chart.destroy();
    };
  }, []);

  // const Chartdata = {
  //   labels: ["May 2022", "", "Jun 2022", "", "Jul 2022", "", "Aug 2022", "", "Sep 2022", "", "Oct 2022"],
  //   datasets: [{
  //     data: [40000, 30000, 45000, 37000, 20000, 13000, 24000, 17000, 43000, 35000, 47000, 35566],
  //     backgroundColor: 'transparent',
  //     borderColor: "rgb(35, 118, 169)",
  //     borderWidth: 2,
  //     pointBorderColor: 'transparent',
  //     pointBorderWidth: 4

  //   }]
  // };
  // const options = {
  //   plugins: {
  //     legend: false
  //   },
  //   scales: {
  //     x: {
  //       grid: {
  //         display: false
  //       }
  //     },
  //     y: {
  //       min: 10000,
  //       max: 50000,
  //       ticks: {
  //         stepSize: 10000,

  //       },
  //       grid: {
  //         borderBash: [10]
  //       }
  //     }
  //   }
  // };

  return (
    <Box
      component='main'
      sx={{
        backgroundColor: '#F0F7FD',
        height: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid
        container
        spacing={{ sm: 3, xs: 1 }}
        sx={{ display: 'flex', flexDirection: 'flex-wrap', justifyContent: 'center', minWidth: { sm: '350px', xs: '310px' }, }} >
        <Typography variant='h2' my='10px' pl={2} sx={{ display: { sm: 'none', xs: 'block', width: '100%', textAlign: 'start' } }}>
          Welcome Benjaminn
        </Typography>

        {/* Chart */}

        <Grid item xs={12} sm={12} md={12} lg={8}>
          <Paper sx={{
            // height: { lg: '585px', md: '585px', xs: '730px' },
            borderRadius: '15px',
          }}
          >
            <Box
              sx={{
                p: 4,
                display: 'flex',
                flexDirection: {
                  md: 'row',
                  xs: 'column',
                },
                justifyContent: 'space-between',
                borderRadius: '15px',
                position: 'relative',
                marginBottom: '20px',
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Stack direction='row'>
                    <Typography variant='h1' sx={{ display: 'inline-block', color: '#93B0C8', }} >  $&nbsp; </Typography>
                    <Typography variant='h1' sx={{ display: 'inline-block', }}> 11.862 </Typography>
                  </Stack>
                  <Stack direction='row' alignItems='center'>
                    <ArrowDropUpIcon style={{ fontSize: '35px', color: '#50AD20' }} />
                    <Typography variant='h5' sx={{ color: '#50AD20', }} > +18.62% </Typography>
                  </Stack>
                </Box>
                <Typography
                  variant='h4'
                  color='#93B0C8'
                  sx={{
                    textAlign: { xs: 'center', md: 'start' },
                    marginTop: { xs: '10px', sm: '10px' },
                  }}
                >
                  Base Capital: $ 10,000.00
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: { xs: '18px', md: '0px' },
                }}
              >
                <Stack
                  spacing={1}
                  direction='row'
                  alignItems='center'
                  justifyContent='center'
                  sx={{
                    boxShadow: '0 0 3px 0 rgba(0,0,0,0.1)',
                    width: '130px',
                    height: '50px',
                    borderRadius: '23px',
                  }}
                >
                  {/* <Box
                    sx={{
                      width: '32px',
                      height: '32px',
                      backgroundColor: '#50ad204f',
                      textAlign: 'center',
                      borderRadius: '100px',
                      color: '#50AD20',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // fontSize: '1.2rem',
                    }}
                  >
                    {' '}
                    <Typography variant='h6'>$</Typography>
                  </Box> */}
                  <IconButton sx={{ backgroundColor: '#DCEFD2', width: '32px', height: '32px' }}>
                    <DollarSymbolIcon />
                  </IconButton>
                  <Typography variant='h5'>USD</Typography>

                  <Box color='#93B0C8' pt='5px'>
                    <KeyboardArrowDownIcon />
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: { xs: 'end' },
                  marginTop: { xs: '19px', md: '0px' },
                }}
              >
                <Box
                  component='span'
                  sx={{
                    display: 'inline-block',
                    alignItems: 'flex-start',
                    backgroundColor: 'Bg_color',
                    // position: 'absolute',
                    // right: "20px",
                    padding: '10px',
                    borderRadius: '20px',
                  }}
                >
                  <Stack direction='row' spacing={1}>
                    <Chip
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        backgroundColor: '#FFFFFF',
                        color: '#3F7DBC',
                      }}
                      size='small'
                      label='6 Months'
                      clickable
                    />
                    <Chip
                      sx={{
                        fontSize: '13px',
                        fontWeight: 500,
                        backgroundColor: 'transparent',
                      }}
                      size='small'
                      label='1 Year'
                      clickable
                    />
                  </Stack>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: { lg: '400px', xs: '390px' },
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: {
                  sm: '0 10px 0 10px',
                  // xs: '0 0px 0 0px'
                },
              }}
            >
              <Box mb={2} component='div' id='chart' width='100%'></Box>
            </Box>
          </Paper>
        </Grid>

        {/* Rewards */}

        <Grid item xs={12} md={6} lg={4} sx={{ minWidth: { sm: '350px', xs: '310px' } }} >
          <Paper
            sx={{
              height: 'auto',
              p: '25px',
              display: 'flex',
              flexDirection: 'column',
              borderRadius: '15px',
              marginTop: {
                sm: '0px',
                xs: '25px',
              },
              position: 'relative'
            }}

          >
            <Accordion key={1} sx={AccordianStyle} defaultExpanded={true} expanded={expand.rewards}>
              <AccordionSummary sx={AccordianSummaryStyle}
                expandIcon={
                  <IconButton onClick={() => setExpand((prev) => ({ ...prev, rewards: !prev?.rewards }))}
                    sx={expandIconStyle}><DownArrow />
                  </IconButton>}
              >
                <Box sx={{ mb: 1 }}>
                  <Typography variant='h3' sx={{ mb: '10px', }}>Rewards </Typography>
                  <Typography variant='h5' sx={{ color: 'Title_Text_color' }}>  Complete tasks and receive gifts </Typography>
                </Box>
              </AccordionSummary >
              <AccordionDetails sx={{ padding: '0px' }}>
                <Box py={1}>
                  <Stack columnGap={2.2} direction='row' >
                    <ChcekList />
                    <Stack direction='column' rowGap={.5} >
                      <Typography variant='h5_1'>Invite a friend using your referral code</Typography>
                      <Typography variant='h5_1' fontWeight='500' color='Title_Text_color' >+10.00 USDT</Typography>
                    </Stack>
                  </Stack>
                  <Stack mt={2.4} columnGap={2.2} direction='row' >
                    <ChcekList />
                    <Stack direction='column' rowGap={.5} >
                      <Typography variant='h5_1'>Get your first coin</Typography>
                      <Typography variant='h5_1' fontWeight='500' color='Title_Text_color' >50% bonus to your next deposit</Typography>
                    </Stack>
                  </Stack>
                  <Stack mt={2.4} columnGap={2.2} direction='row' >
                    <ChcekList />
                    <Stack direction='column' rowGap={.5} >
                      <Typography variant='h5_1'>Verify your email</Typography>
                      <Typography variant='h5_1' fontWeight='500' color='Title_Text_color' >Get an NFT as your profile pic</Typography>
                    </Stack>
                  </Stack>
                  <Stack mt={2.4} columnGap={2.2} direction='row' >
                    <Box component='span' sx={{ display: 'block', width: '31px', height: '31px', backgroundColor: '#93B0C8', color: '#FFFFFF', borderRadius: '50%', textAlign: 'center', lineHeight: '31px', }} >4</Box>
                    <Stack direction='column' rowGap={.5} >
                      <Typography variant='h5_1'>Sell your first BTC</Typography>
                      <Typography variant='h5_1' fontWeight='500' color='Title_Text_color' >+100.00 USDT</Typography>
                    </Stack>
                  </Stack>
                  <Stack mt={2.4} columnGap={2.2} direction='row'>
                    <Box component='span' sx={{ display: 'block', width: '31px', height: '31px', backgroundColor: '#93B0C8', color: '#FFFFFF', borderRadius: '50%', textAlign: 'center', lineHeight: '31px', }} >5</Box>
                    <Stack direction='column' rowGap={.5} >
                      <Typography variant='h5_1'>Create your own portfolio</Typography>
                      <Typography variant='h5_1' fontWeight='500' color='Title_Text_color' >+0.01 BTC</Typography>
                    </Stack>
                  </Stack>
                </Box>
                <Button variant='Standard' sx={{ width: '100%', marginTop: 2, marginBottom: 1, fontSize: '19px', fontWeight: '400', letterSpacing: '.6px' }} > Get a Reward </Button>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        {/* NEWS */}

        <Grid item xs={12} md={6} lg={4} sx={{ minWidth: { sm: '350px', xs: '310px' }, }}   >
          <Paper
            sx={{
              padding: '20px',
              borderRadius: '15px',
              marginTop: {
                sm: '0px',
                xs: '25px',
              },
            }}
          >
            <Accordion key={2} sx={AccordianStyle} defaultExpanded={true} expanded={expand?.news}>
              <AccordionSummary sx={AccordianSummaryStyle}
                expandIcon={
                  <IconButton onClick={() => setExpand((prev) => ({ ...prev, news: !prev?.news }))}
                    sx={expandIconStyle}><DownArrow />
                  </IconButton>}
              >
                <Box mt={{ xs: 2, sm: 0 }}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: expand?.news ? 'row' : 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Typography sx={{ letterSpacing: '1px', }} variant='h3' > News</Typography>
                  {expand?.news ? <Typography variant='h5' color='#50AD20'>view all</Typography> :
                    <Typography variant='h5' pt={1.2} sx={{ color: 'Title_Text_color' }}>See all your news</Typography>}
                </Box>
              </AccordionSummary >
              <AccordionDetails sx={{ padding: '0px' }}>
                <Box>
                  {NewsData.map((itm) => {
                    return (
                      <Card
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                          boxShadow: 'none',
                          marginBottom: '18px',
                        }}
                      >
                        <CardMedia sx={{ height: '65px', width: '65px', borderRadius: '10px', }}
                          // image={`http://localhost:9672/api/v1/images/${itm.image_data}`}
                          image={itm?.img} />

                        <CardContent>
                          <Typography variant='h5_1' sx={{ color: '#1F3048', }} > {' '} {itm.title} </Typography>
                          <Typography variant='h6' sx={{ color: '#8A8C8F', }}> Loreum ipsum text here </Typography>
                        </CardContent>
                        <Typography variant='h6' sx={{ color: '#3F7DBC', marginLeft: '15px', }} > Read More </Typography>
                      </Card>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        {/* upcoming Events */}

        <Grid item xs={12} md={6} lg={4} sx={{ minWidth: { sm: '350px', xs: '310px' }, }} >
          <Paper
            sx={{
              padding: '20px',
              // height: "250px",
              borderRadius: '15px',
              marginTop: {
                sm: '0px',
                xs: '25px',
              },
              '.MuiPaper-root': {
                borderRadius: '15px'
              }
            }}
          >
            <Accordion key={3} sx={AccordianStyle} defaultExpanded={true} expanded={expand?.upcoming_events}>
              <AccordionSummary sx={AccordianSummaryStyle}
                expandIcon={
                  <IconButton onClick={() => setExpand((prev) => ({ ...prev, upcoming_events: !prev?.upcoming_events }))}
                    sx={expandIconStyle}><DownArrow />
                  </IconButton>}
              >
                <Box mt={{ xs: 2, sm: 0 }}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: expand?.upcoming_events ? 'row' : 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    // marginBottom: '20px',
                    // px: '25px',
                    // paddingTop: '20px',
                    width: '100%',
                  }}
                >
                  <Typography sx={{ letterSpacing: '1px', }} variant='h3'> Upcoming Events </Typography>
                  {expand?.upcoming_events ? <Typography variant='h5' color='#50AD20'>view all</Typography> :
                    <Typography variant='h5' pt={1.2} sx={{ color: 'Title_Text_color' }}>See all your upcoming events</Typography>}
                </Box>
              </AccordionSummary >
              <AccordionDetails sx={{ padding: '0px' }}>
                <Box>
                  {UpcomingEventsData.map((itm) => {
                    return (
                      <Card
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '7px 15px 14px 15px',
                          borderRadius: '15px',
                          backgroundColor: '#F0F7FD',
                          marginBottom: '15px',
                          boxShadow: 'none',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '10px',
                          }}
                        >
                          <Box>
                            <Typography variant='h5_1' sx={{ color: '#3F7DBC', }} >{itm.title1} </Typography> <br />
                            <Typography variant='h5_1' sx={{ color: '#1F3048', }}> {itm.title2}</Typography>
                          </Box>
                        </Box>
                        <Box sx={{ marginBottom: '13px', }} >
                          <Typography variant='h7' sx={{ color: '#8A8C8F', }} > {itm.des}</Typography>
                        </Box>
                        <Box rowGap={2}
                          sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <Box>
                            <Typography variant='h7' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              <AccessTimeIcon sx={{ fontSize: '20px', marginRight: '5px', }} />
                              46{' '}
                              <Box component='span' mx='3px' color='#8A8C8F'>
                                hours
                              </Box>
                              25{' '}
                              <Box component='span' mx='3px' color='#8A8C8F'>
                                min
                              </Box>
                              3{' '}
                              <Box component='span' mx='3px' color='#8A8C8F'>
                                sec
                              </Box>
                            </Typography>
                          </Box>
                          <Stack spacing={1} direction='row'>
                            <Button variant='Standard'
                              sx={{
                                borderRadius: '4px',
                                fontSize: '12px',
                                letterSpacing: '.7px',
                                width: '73px',
                                height: '21px',
                                // padding: '2px 17px 2px 17px',
                              }}
                            >
                              Web3
                            </Button>
                            <Button
                              sx={{
                                borderRadius: '4px',
                                fontSize: '12px',
                                letterSpacing: '.7px',
                                width: '73px',
                                height: '21px',
                              }}
                              variant='Standard'
                            >
                              Exchange
                            </Button>
                          </Stack>
                        </Box>
                      </Card>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>

        {/* RECENT TRANSACTION */}

        <Grid item xs={12} md={6} lg={4} sx={{ minWidth: { sm: '350px', xs: '310px' }, }} >
          <Paper sx={{
            padding: '25px',
            borderRadius: '15px',
            marginTop: {
              sm: '0px',
              xs: '25px',
            },
          }}
          >
            <Accordion key={3} sx={AccordianStyle} defaultExpanded={true} expanded={expand?.transaction}>
              <AccordionSummary sx={AccordianSummaryStyle}
                expandIcon={
                  <IconButton onClick={() => setExpand((prev) => ({ ...prev, transaction: !prev?.transaction }))}
                    sx={expandIconStyle}><DownArrow />
                  </IconButton>}
              >
                <Box mt={{ xs: 2, sm: 0 }}
                  sx={{
                    display: 'flex',
                    flexDirection: { xs: expand?.transaction ? 'row' : 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    // marginBottom: '27px',
                    width: '100%'
                  }} >
                  <Typography sx={{ letterSpacing: '1px', }} variant='h3'>Recent Transactions </Typography>
                  {expand?.transaction ? <Typography variant='h5' color='#50AD20'>view all</Typography> :
                    <Typography variant='h5' pt={1.2} sx={{ color: 'Title_Text_color' }}>See your recent transactions</Typography>}

                </Box>
              </AccordionSummary >
              <AccordionDetails sx={{ padding: '0px' }}>
                <Box>
                  {RecentTTransactionData.map((itm) => {
                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '28px',
                        }}
                      >
                        <Box columnGap={{ xs: 1, sm: 3 }}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          {itm.status ? <PlusIcon /> : <MinusIcon />}
                          <Box>
                            <Typography variant='h5_1' >{itm.date} </Typography>
                          </Box>
                        </Box>
                        <Box mr={1}>
                          {itm.status ? (
                            <Typography variant='h5_1' color='#50AD20'>  + ${itm.price}</Typography>
                          ) : (
                            <Typography variant='h5_1' color='#E84C3D'>- ${itm.price}</Typography>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
      {/* </Container> */}
    </Box >
  );
}
