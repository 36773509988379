import React from 'react'

import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Chip from '@mui/material/Chip';
import { Stack, Pagination, PaginationItem } from '@mui/material';

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { borderRadius, fontSize } from '@mui/system';


import SouthIcon from '@mui/icons-material/South';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import BitCoinIcon from '../img/bitcoin.png';
import { ReactComponent as StarIcon } from '../img/StarIcon.svg'
import { ReactComponent as PaginationForwardIcon } from '../img/PaginationForwardIcon.svg';
import { ReactComponent as PaginationBackArrowIcon } from '../img/PaginationBackArrow.svg';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import { BiLogoBitcoin } from "react-icons/bi"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { StyledTableRow, StyledTableCell } from "../Theme"

// function createData(
//     jhk,
//     Coin,
//     Price,
//     h,
//     tfh,
//     sd,
//     tfhv,
//     mktcap
// ) {
//     return { jhk, Coin, Price, h, tfh, sd, tfhv, mktcap };
// }

const TableData = [
    {
        coin: [<BiLogoBitcoin />, 'Bitcoin', 'BTC'],
        price: "25,769.19",
        h1: ' 0.2',
        _h24: '- 3.9',
        d7: '-4.6',
        volume: "20,351,870,111",
        mkt_cap: "500,335,615,091"
    }

]
// const rows = [
//     createData(1, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
//     createData(2, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
//     createData(3, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
//     createData(4, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
//     createData(5, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
//     createData(6, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
//     createData(7, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
//     createData(8, [<BiLogoBitcoin />, 'Bitcoin', 'BTC'], "25,769.19", 0.2, -3.9, -4.6, "20,351,870,111", "500,335,615,091"),
// ];






export default function Crypto_prices() {
    return (
        <Box backgroundColor='#F0F7FD' width='100%' height='auto' pl={1} overflow='hidden'>
            <Box sx={{
                overflowX: { xs: 'scroll' },
                msoverflowStyle: "none",
                scrollbarWidth: 'none',
                '&::-webkit-scrollbar': {
                    width: 0,
                }

            }}>
                <Box columnGap={2} rowGap={3} sx={{
                    width: { xs: 'fit-content', sm: '100%' },
                    paddingTop: '20px',
                    display: 'flex',
                    // flexDirection: "row",
                    // alignItems: "start",
                    // justifyContent: 'flex-start',
                    flexWrap: { sm: 'wrap', xs: "no-wrap" },
                    justifyContent: 'space-evenly',

                }}>
                    <Card sx={{
                        minWidth: '350px',
                        height: '150px',
                        backgroundColor: 'white',
                        borderRadius: '11px',
                        borderLeft: '11px solid #E84C3D',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // margin: '10px',
                    }}>
                        <CardContent>
                            <Typography py={1} variant='h3' color='#CACACA'>
                                Market Capitalization
                            </Typography>
                            <Stack py={1} direction='row' alignItems='center'>
                                <Typography variant='h2'>$1,137,310,357,403</Typography>
                                <Typography px={1} variant='h5_2' color='#E84C3D' sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}
                                > -4.5% <SouthIcon sx={{
                                    fontSize: '1rem'
                                }} /></Typography>
                            </Stack>
                        </CardContent>
                    </Card>

                    <Card sx={{
                        minWidth: '350px',
                        height: '150px',
                        backgroundColor: 'white',
                        borderRadius: '11px',
                        borderLeft: '11px solid #50AD20',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // margin: '10px'
                    }}>
                        <CardContent>
                            <Typography py={1} variant='h3' color='#CACACA'>
                                24h Trading Volume
                            </Typography>
                            <Stack py={1} direction='row' alignItems='center'>
                                <Typography variant='h2'>$1,137,310,357,403</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{
                        minWidth: '350px',
                        height: '150px',
                        backgroundColor: 'white',
                        borderRadius: '11px',
                        borderLeft: '11px solid #93B0C8',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        // margin: '10px'
                    }}>
                        <CardContent>
                            <Typography py={1} variant='h3' color='#CACACA'>
                                BTC Market Cap Dominance
                            </Typography>
                            <Stack py={1} direction='row' alignItems='center'>
                                <Typography variant='h2'>43.99%</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
            <Box component='div' mt={4} sx={{ paddingLeft: { xs: '0px', sm: '0px' } }}>
                <TableContainer sx={{ backgroundColor: 'White_color', borderRadius: '10px' }}>
                    <Table aria-label="simple table">
                        <TableHead >
                            <StyledTableRow sx={{ borderBottom: { sm: '0px !important', xs: '1px solid #CACACA !important' } }}>
                                <StyledTableCell sx={{paddingLeft:'30px !important'}}>#</StyledTableCell>
                                <StyledTableCell>Coin</StyledTableCell>
                                <StyledTableCell>Price</StyledTableCell>
                                <StyledTableCell>1h</StyledTableCell>
                                <StyledTableCell>24h</StyledTableCell>
                                <StyledTableCell>7d</StyledTableCell>
                                <StyledTableCell>24h Volume</StyledTableCell>
                                <StyledTableCell>Mkt Cap</StyledTableCell>
                                <StyledTableCell>Last 7 Days</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {[1, 1, 1, 1, 1, 1, 1, 1, 1].map((itm, index) => (
                                <StyledTableRow sx={{ borderBottom: { sm: '0px !important', xs: '1px solid #CACACA !important' } }}>
                                    <StyledTableCell sx={{paddingLeft:'30px !important'}}>
                                        <Box columnGap={1.5} sx={{ display: 'flex',alignItems:'center' }}>  <StarIcon /> {index + 1} </Box>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', fontWeight: '500 !important' }}>
                                            <Box component='img' mr={1} src={BitCoinIcon} sx={{ width: '16px', height: '16px' }}></Box>
                                            Bitcoin <Typography ml={1} color='#8A8C8F'>BTC</Typography>
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell>$25,769.19</StyledTableCell>
                                    <StyledTableCell sx={{ color: '#50AD20' }}>0.2</StyledTableCell>
                                    <StyledTableCell sx={{ color: 'Errors' }}>-3.9</StyledTableCell>
                                    <StyledTableCell sx={{ color: 'Errors' }}>-4.6</StyledTableCell>
                                    <StyledTableCell>20,351,870,111</StyledTableCell>
                                    <StyledTableCell>500,335,615,091</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Stack mt={4} direction='row' justifyContent='center' spacing={2}>
                    <Pagination
                        count={5}
                        sx={{ '.MuiPaginationItem-circular': { backgroundColor: 'white' }, '.Mui-selected': { backgroundColor: '#3F7DBC !important', color: 'white' }, '.Mui-selected:hover': { backgroundColor: '#3F7DBC', color: 'white' } }}
                        renderItem={(item) => (
                            <PaginationItem slots={{ previous: PaginationBackArrowIcon, next: PaginationForwardIcon }}  {...item}
                            />)}
                    />
                </Stack>

                {/* <TableContainer component={Paper} sx={{ borderRadius: '10px', border: 'none' }}>
                    <Table sx={{ minWidth: 650, }} aria-label="simple table">
                        <TableHead>
                            <TableRow >
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} >#</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px', textAlign: "center" }} align="right">Coin</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} align="right"> Price</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} align="right">1h</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} align="right">24h</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} align="right">7d</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} align="right">24h Volume</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} align="right">Mkt Cap</TableCell>
                                <TableCell sx={{ border: 'none', fontSize: '15px', padding: '25px' }} align="right">Last 7 Days</TableCell>

                            </TableRow >
                        </TableHead >
                        <TableBody sx={{}}>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.jhk}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, border: 'none' }}
                                >
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', display: 'flex', alignItems: 'center' }} component="th" scope="row"><StarOutlineIcon sx={{ width: "23px", marginRight: '10px' }} /> {row.jhk} </TableCell>
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', }} align="right">
                                        <Stack direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box component='span' sx={{
                                                color: 'orange',
                                                fontSize: '1.3rem',
                                                marginRight: '5px',
                                                paddingTop: "5px"

                                            }}>{row.Coin[0]}</Box>
                                            {row.Coin[1]}
                                            <Box component='span' sx={{
                                                color: "grey",
                                                paddingLeft: '8px',
                                                fontSize: '.8rem',
                                            }}>{row.Coin[2]}</Box>
                                        </Stack>
                                    </TableCell>
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', color: '' }} align="right">${row.Price}</TableCell>
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', color: '#50AD20' }} align="right">{row.h}%</TableCell>
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', color: '#E84C3D' }} align="right">{row.tfh}%</TableCell>
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', color: '#E84C3D' }} align="right">{row.sd}%</TableCell>
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', color: '' }} align="right">${row.tfhv}</TableCell>
                                    <TableCell sx={{ border: 'none', padding: '25px', fontSize: '15px', fontWeight: '500', color: '' }} align="right">${row.mktcap}</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table >
                </TableContainer > */}
                {/* <IconButton component='div' sx={{
                    position: 'absolute',
                    zIndex: '1',
                    bottom: { sm: '0%', xs: '50%' },
                    right: { sm: '5%', xs: '-1%' },
                    width: '50px',
                    height: '50px',
                    backgroundColor: 'white',
                    borderRadius: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                    color: '#3F7DBC',
                    transition: '.3s'
                }}>
                    <KeyboardArrowDownIcon sx={{
                        fontSize: '2rem',
                        display: { sm: 'block', xs: 'none' }
                        // paddingLeft: '8px'
                    }} />
                    <ArrowForwardIosIcon sx={{
                        fontSize: '1.5rem',
                        display: { sm: 'none', xs: 'block' },

                        // paddingLeft: '8px'
                    }} />

                </IconButton> */}
            </Box >
        </Box >
    )
}

