import React from 'react';
import SumsubWebSdk from "@sumsub/websdk-react"

import { useEffect, useState } from 'react';

import axios from 'axios';
import loadgif from "../img/kyc/loading__.gif"
import Box from "@mui/material/Box";

export default function Sumsub() {

    const [accessToken, setAccessToken] = useState("");
    const [loading, setloading] = useState(true)


    useEffect(() => {
        (async () => {

            await axios.post("http://localhost:9672/api/v1/sumsub-access-token", { id: "64c7af60146b9045da88bf9e", type: "DL" }).then(res => {

                //console.log("jhvkug", res);
                setAccessToken(res.data.token)
                setloading(false)
            })
                .catch(err => {
                    console.log("err");
                    setAccessToken("")
                    setloading(false)
                })



        })()

    }, [])




    return (<Box sx={{ backgroundColor: "#F0F7FD", width: '100%', height: "100%" }}>

        {loading ?
            (<Box sx={{ width: '100%', height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <img style={{ backgroundColor: "#F0F7FD" }} src={loadgif} width="200" />
            </Box>)
            : (

                <div> {accessToken &&

                    <SumsubWebSdk

                        // testEnv={true}

                        accessToken={accessToken}

                        expirationHandler={() => accessToken}

                        config={{

                            lang: "en-us",

                        }}

                        // options={{ addViewportTag: false, adaptIframeHeight: true }}

                        onMessage={(data, payload) => console.log("onMessage", data, payload)}

                        onError={(data) => console.log("onError", data)}

                    />

                }</div>
            )
        }

    </Box>

    )

}