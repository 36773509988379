import React from 'react'
import { Box, Typography, Button, Stack } from "@mui/material";
import Sandbox from '../../img/Sandbox.png';
import Axs from '../../img/axs.png';

const upcomingEvents = [
    { id: 1, name: 'SAND', title: 'The Sandbox', description: "In The Sandbox, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.", icon: Sandbox },
    { id: 2, name: 'AXS', title: 'Axie Infinity', description: "In The Sandbox, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.", icon: Axs }
]

export default function ConferenceTrendingData({ conferenceData, trendingData }) {
    return (
        <Box sx={{ display: 'flex', gap: '50px', flexDirection: 'column', width: '100%' }}>
            {conferenceData?.map((item, index) => (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1, backgroundColor: '#F0F7FD', borderRadius: '21px', padding: '20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                            <Typography variant='h4' color='primary' sx={{ fontWeight: '600 !important' }}>{item.name}</Typography>
                            <Typography variant='h3' sx={{ fontWeight: '600 !important' }}>{item.title}</Typography>
                        </Box>
                        <Box
                            component='img'
                            src={item.icon}
                            sx={{ width: '50px', height: '50px' }}
                        />
                    </Box>
                    <Typography variant='h6' color='Grey'>{item.description}</Typography>
                    <Stack spacing={2} direction='row'>
                        <Button variant='Standard' sx={{ fontSize: '13px', height: '25px', padding: '2px 20px 2px 20px', borderRadius: '5px !important', letterSpacing: '.6px' }} >
                            Web3
                        </Button>
                        <Button variant='Standard' sx={{ fontSize: '13px', height: '25px', padding: '2px 20px 2px 20px', borderRadius: '5px !important', letterSpacing: '.6px' }} >
                            Exchange
                        </Button>
                    </Stack>

                </Box>
            ))}

            {trendingData?.map((item, index) => (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', flex: 1, backgroundColor: '#F0F7FD', borderRadius: '21px', padding: '20px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', }}>
                            <Typography variant='h4' color='primary' sx={{ fontWeight: '600 !important' }}>{item.name}</Typography>
                            <Typography variant='h3' sx={{ fontWeight: '600 !important' }}>{item.title}</Typography>
                        </Box>
                        <Box
                            component='img'
                            src={item.icon}
                            sx={{ width: '50px', height: '50px' }}
                        />
                    </Box>
                    <Typography variant='h6' color='Grey'>{item.description}</Typography>
                    <Stack spacing={2} direction='row'>
                        <Button variant='Standard' sx={{ fontSize: '13px', height: '25px', padding: '2px 20px 2px 20px', borderRadius: '5px !important', letterSpacing: '.6px' }} >
                            Web3
                        </Button>
                        <Button variant='Standard' sx={{ fontSize: '13px', height: '25px', padding: '2px 20px 2px 20px', borderRadius: '5px !important', letterSpacing: '.6px' }} >
                            Exchange
                        </Button>
                    </Stack>
                </Box>
            ))}
        </Box>
    )
}
