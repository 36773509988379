import React from 'react'
import { Box, Typography, IconButton } from "@mui/material";
import { ReactComponent as UpcomingEventTimer } from '../../img/UpcomingEventTimer.svg';
import UpcomingEventsData from './UpcomingEvents';
import ConferenceTrendingData from './ConferenceTrending';
import Sandbox from '../../img/Sandbox.png';
import Axs from '../../img/axs.png';
import Aave from '../../img/Aave.png';
import Matic from '../../img/matic.png';
import Bitcoin from '../../img/bitcoin.png';
import Mana from '../../img/mana.png';


const conferenceData = [
    { id: 1, name: 'MATIC', title: 'Conference 1', description: "In The Sandbox, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.", icon: Matic },
    { id: 2, name: 'Mana', title: 'Conference 2', description: "In The Sandbox, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.", icon: Mana }
]

const trendingData = [
    { id: 1, name: 'Trending Tag 2', title: 'Trending Headline 1', description: "In The Sandbox, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.", icon: Bitcoin },
    { id: 2, name: 'Trending Tag 1', title: 'Trending Headline 2', description: "In The Sandbox, game designers can create custom 3D NFTs that can be used across the ecosystem and they can be redeem to your own wallet.", icon: Aave }
]

export default function UpcomingEvents() {
    return (
        <Box sx={{ backgroundColor: '#F0F7FD', pb: 3 }}>
            <Box backgroundColor='#F0F7FD' height='auto'>
                <Box sx={{
                    backgroundColor: 'white',
                    // margin: {
                    //     sm: '35px 25px 0px 25px ',
                    //     xs: '35px 5px 10px 5px '
                    // },
                    padding: {
                        sm: '20px 33px 35px 33px',
                        xs: '20px 15px 35px 15px'
                    },
                    borderRadius: '18px',

                }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box columnGap={4} rowGap={1} sx={{ display: 'flex', alignItems: 'start', flexDirection: { xs: 'column', md: 'row' } }}>
                            <Typography variant='h2' sx={{ wordWrap: 'none' }}>The Upcoming Events</Typography>
                            {/* <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <UpcomingEventTimer />
                                <Typography variant='h4' >
                                    <Box component='span' >46</Box>
                                    hours <Box component='span'>25</Box>
                                    min <Box component='span'>3</Box>
                                    sec</Typography>
                            </Box> */}
                            <Typography variant='h5'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                    // fontSize: '13px',
                                }}
                            >
                                <IconButton sx={{ width: '45px', height: '45px' }}>  <UpcomingEventTimer /></IconButton>
                                46{' '}
                                <Box component='span' mx='3px' color='#8A8C8F'>
                                    hours
                                </Box>
                                25{' '}
                                <Box component='span' mx='3px' color='#8A8C8F'>
                                    min
                                </Box>
                                3{' '}
                                <Box component='span' mx='3px' color='#8A8C8F'>
                                    sec
                                </Box>
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <UpcomingEventsData />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box mt={4} columnGap={4} rowGap={4} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
                <Box sx={{ backgroundColor: '#F0F7FD', height: 'auto', }}  >
                    <Box sx={{
                        backgroundColor: 'white',
                        padding: {
                            sm: '20px 33px 35px 33px',
                            xs: '20px 15px 35px 15px'
                        },
                        borderRadius: '18px',

                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h2' sx={{ mb: 2 }}>Trending</Typography>
                            <ConferenceTrendingData conferenceData={conferenceData} />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ backgroundColor: '#F0F7FD', height: 'auto', }}  >
                    <Box sx={{
                        // width:'40%',
                        backgroundColor: 'white',
                        padding: {
                            sm: '20px 33px 35px 33px',
                            xs: '20px 15px 35px 15px'
                        },
                        borderRadius: '18px',

                    }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant='h2' sx={{ mb: 2, ml: { xs: 1, sm: 0 } }}>Conferences</Typography>
                            <ConferenceTrendingData trendingData={trendingData} />
                        </Box>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}
