import React, { useState } from 'react'

import { TextField, Box, Stack, Button, Modal } from '@mui/material';

import axios from 'axios';


export default function DashboardUpcomingEventsAdd({ geteventsdata }) {


    const [addeventmodalopen, setaddeventmodalopen] = useState(false)

    const [addevent, setaddevent] = useState({
        title: "",
        subtitle: "",
        description: ""
    })


    const handlechange = (e) => {
        
        const { name, value } = e.target

        setaddevent((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })

    }



    const onSubmitform = async (e) => {

        try {
            e.preventDefault();
        


            const token = localStorage.getItem("Admintoken")
            const headers = {
                Authorization: `Bearer ${token}`,

            };

            await axios.post("http://localhost:9672/api/v1/upcomingevents",
                { "title": addevent.title, "subtitle": addevent.subtitle, "description": addevent.description },{headers})

            setaddevent({
                title: "",
                subtitle: "",
                description: ""
            })
            setaddeventmodalopen(false)

            geteventsdata();

        } catch (error) {
            console.log(error.message);

        }
    }



    return (
        <Box sx={{ mt: 2, padding: '20px', display: "flex", justifyContent: "flex-end" }}>

            <Button variant="contained" color="success" onClick={() => { setaddeventmodalopen(true) }}>Add Event</Button>

            <Modal
                open={addeventmodalopen}
                onClose={() => { setaddeventmodalopen(false) }}
            >

                <Box component="form" onSubmit={onSubmitform} sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '8px',
                    minWidth: '300px'
                }}>
                    <TextField sx={{ marginBottom: '20px' }}
                        label="Title"
                        name="title"
                        value={addevent.title}
                        variant='standard'
                        onChange={handlechange}
                        fullWidth
                        required
                    />

                    <TextField sx={{ marginBottom: '20px' }}
                        label="Subtitle"
                        name="subtitle"
                        value={addevent.subtitle}
                        variant='standard'
                        onChange={handlechange}
                        fullWidth
                        required
                    />
                    <TextField sx={{ marginBottom: '20px' }}
                        label="Description"
                        name="description"
                        value={addevent.description}
                        variant='standard'
                        onChange={handlechange}
                        fullWidth
                        required
                    />

                    <Stack direction="row" justifyContent="space-between">
                        <Button variant="contained" color="error" onClick={() => { setaddeventmodalopen(false) }}>close</Button>
                        <Button type="submit" variant="contained" color="success">Add</Button>

                    </Stack>
                </Box>

            </Modal >
        </Box >

    )
}
