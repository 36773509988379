import React, { useState } from 'react'
import {Box, Typography, Button, IconButton} from "@mui/material";
import { settingsMenuData } from './SettingsData';
import { ReactComponent as EditIcon } from '../../img/edit_pencil.svg';
import { ReactComponent as AvatarIcon } from '../../img/avatar_temp.svg';
import UserProfileInfo from '../../components/UserAccount/UserProfileInfo';
import UserPersonalInfo from '../../components/UserAccount/UserPersonalInfo';
import UserAddressInfo from '../../components/UserAccount/UserAddressInfo';
import ConnectedAccounts from '../../components/BankInformation/ConnectedAccounts';
import BankStatements from '../../components/BankInformation/BankStatements';
import QRcode from '../../components/Wallet/QRcode';
import WalletAddress from '../../components/Wallet/WalletAddress';
import WalletNeedHelp from '../../components/Wallet/NeedHelp';
import Sumsub from '../Sumsubkyc';
import { useAuth0 } from '@auth0/auth0-react';

export default function Settings() {
  const { logout, user, isAuthenticated, loginWithRedirect } = useAuth0();
  const [isSelected, setIsSelected] = useState({user_account:true, bank_information:false,wallets:false,deposits:false,kyc_docusign:false,subscription_plan:false,default_currency:false,help:false,logout:false})
  const localUserData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : ""
  const handleMenuClick = (key) => {
    const selectedObject = {user_account:false, bank_information:false,wallets:false,deposits:false,kyc_docusign:false,subscription_plan:false,default_currency:false,help:false,logout:false}
    setIsSelected({...selectedObject, [key]:true})
    if(key == "logout") {
      localStorage.removeItem('userData')
      localStorage.removeItem('token')
      logout();
    }
  }
  console.log('localUserData ',localUserData)

  return (
    <Box backgroundColor='#F0F7FD' height='auto'>
        <Box sx={{

        backgroundColor: 'white',
        height:'auto',
        margin: {
            sm: '20px 15px 20px 25px ',
            xs: '25px 5px 20px 5px '
        },
        padding: {
            sm: '4px',
            xs: '4px'
        },
        borderRadius: "18px"

        }}>

        <Box sx={{margin:'10px', display:'flex', flexDirection: {xs:'column',md:'row'}}}>
            <Box sx={{ margin:'10px', borderRadius:'18px', padding:'30px 10px', display:'flex', flexDirection:'column',gap:'25px', width:{xs:'100%',md:'24%'}, backgroundColor:' #EAF4FF66'}}>
              {settingsMenuData?.map((item,index) => (
                <Button variant='text' sx={{textTransform:'none', color: isSelected[item.key] == true ? 'green':  '#000000', fontSize:'18px'}} onClick={() => handleMenuClick(item.key)}>{item.name}</Button>
              ))}
            </Box>
            <Box sx={{width:{md:'70%',lg:'100%'}}}>
              <UserProfileInfo />
              {isSelected.user_account == true && <>
              <UserPersonalInfo />
              <UserAddressInfo /></>}
              {isSelected.bank_information == true && <><ConnectedAccounts />
              <BankStatements /></>}
              {isSelected.wallets == true &&<><QRcode/>
                <WalletAddress />
                <WalletNeedHelp/>
              </> }
              {isSelected.kyc_docusign == true && <>
                <Sumsub />
              </>}

            </Box>
        </Box>
        </Box>
    </Box>
  )
}
