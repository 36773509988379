import React, { useEffect } from 'react'



import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { borderRadius, textAlign } from '@mui/system';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as DollarSymbolIcon } from '../img/DollarSymbol.svg';
import { ReactComponent as AddButtonIcon } from '../img/AddButtonIcon.svg';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { BiLogoBitcoin } from "react-icons/bi"
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import BitCoinIcon from '../img/bitcoin.png';
import { ReactComponent as PlusGreyIcon } from '../img/PlusGreyIcon.svg'
import { ReactComponent as DotsIcon } from '../img/DotsIcon.svg'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTableRow, StyledTableCell } from "../Theme"
import ApexCharts from 'apexcharts';

// function createData(
//     name: string,
//     price: number,
//     tfh: number,
//     holdings: number,
//     avgbuyprice: number,
//     proloss: number,

// ) {
//     return { name, price, tfh, holdings, avgbuyprice, proloss };
// }

// const rows = [
//     createData([<BiLogoBitcoin />, 'Bitcoin', '(BTC)'], 426.67, 1.31, 200.63, 411.39, [7.14, 13.7]),
//     createData([<BiLogoBitcoin />, 'Bitcoin', '(BTC)'], 426.67, 1.31, 200.63, 411.39, [7.14, 13.7]),
//     createData([<BiLogoBitcoin />, 'Bitcoin', '(BTC)'], 426.67, 1.31, 200.63, 411.39, [7.14, 13.7]),
//     createData([<BiLogoBitcoin />, 'Bitcoin', '(BTC)'], 426.67, 1.31, 200.63, 411.39, [7.14, 13.7]),
//     createData([<BiLogoBitcoin />, 'Bitcoin', '(BTC)'], 426.67, 1.31, 200.63, 411.39, [7.14, 13.7]),
//     createData([<BiLogoBitcoin />, 'Bitcoin', '(BTC)'], 426.67, 1.31, 200.63, 411.39, [7.14, 13.7]),
//     createData([<BiLogoBitcoin />, 'Bitcoin', '(BTC)'], 426.67, 1.31, 200.63, 411.39, [7.14, 13.7]),

// ];


const rows = [
    {
        id: 1,
        name: [<BiLogoBitcoin />, 'Bitcoin', '(BTC)'],
        price: 426.67,
        tfh: 1.31,
        holdings: 200.63,
        avgbuyprice: 411.39,
        proloss: [7.14, 13.7]
    },
    {
        id: 2,
        name: [<BiLogoBitcoin />, 'Bitcoin', '(BTC)'],
        price: 426.67,
        tfh: 1.31,
        holdings: 200.63,
        avgbuyprice: 411.39,
        proloss: [7.14, 13.7]
    },
    {
        id: 3,
        name: [<BiLogoBitcoin />, 'Bitcoin', '(BTC)'],
        price: 426.67,
        tfh: 1.31,
        holdings: 200.63,
        avgbuyprice: 411.39,
        proloss: [7.14, 13.7]
    },
    {
        id: 4,
        name: [<BiLogoBitcoin />, 'Bitcoin', '(BTC)'],
        price: 426.67,
        tfh: 1.31,
        holdings: 200.63,
        avgbuyprice: 411.39,
        proloss: [7.14, 13.7]
    },
    {
        id: 5,
        name: [<BiLogoBitcoin />, 'Bitcoin', '(BTC)'],
        price: 426.67,
        tfh: 1.31,
        holdings: 200.63,
        avgbuyprice: 411.39,
        proloss: [7.14, 13.7]
    },
    {
        id: 6,
        name: [<BiLogoBitcoin />, 'Bitcoin', '(BTC)'],
        price: 426.67,
        tfh: 1.31,
        holdings: 200.63,
        avgbuyprice: 411.39,
        proloss: [7.14, 13.7]
    },
    {
        id: 7,
        name: [<BiLogoBitcoin />, 'Bitcoin', '(BTC)'],
        price: 426.67,
        tfh: 1.31,
        holdings: 200.63,
        avgbuyprice: 411.39,
        proloss: [7.14, 13.7]
    },
]


export default function Portfolio() {


    useEffect(() => {
        // Create the chart in the useEffect hook
        const options = {
            chart: {
                type: "area",
                height: '500px',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },

            yaxis: {
                labels: {
                    style: {
                        fontSize: "13px",
                        fontWeight: '500',
                    }
                }
            },

            series: [
                {
                    name: 'Sales',
                    data: [1000, 1050, 1030, 1150, 1143, 1147, 1152, 1170, 1190, 1160, 1256, 1240, 1220, 1330, 1320, 1385, 1382, 1355, 1332, 1343, 1365, 1343, 1372, 1394, 1365, 1373, 1332, 1373, 1395, 1423, 1435, 1325]
                },
            ],
            colors: ['#47A663'],

            fill: {
                type: "gradient",
                colors: ['#47A663'],
                gradient: {
                    shadeIntensity: 1,
                    opacityFrom: 0.7,
                    opacityTo: 0.9,
                    stops: [0, 90, 100]
                }
            },

            xaxis: {
                categories: ['FEB 22', "", "", 'MAR 22', "", "", 'APR 22', "", "", 'MAY 22', "", "", 'JUN 22', "", "", 'JUL 22', "", "", 'AUG 22', "", "", 'SEP 22', "", "", 'OCT 22', "", "", 'NOV 22', "", "", 'DEC 22'],

                labels: {
                    style: {
                        fontSize: "13px",
                        fontWeight: '500',
                    }
                }
            },



        };

        const chart = new ApexCharts(document.querySelector('#chart'), options);
        chart.render();

        // Cleanup the chart when the component is unmounted
        return () => {
            chart.destroy();
        };
    }, []);











    return (
        <>
            <Box mt={2} sx={{ backgroundColor: "#F0F7FD", width: '100%', height: 'auto', }}>
                <Paper sx={{ backgroundColor: 'white', padding: { sm: '20px 35px 20px 34px', xs: '20px 5px 20px 5px' }, borderRadius: '20px', height: 'auto' }}>
                    <Stack direction={{ md: 'row', xs: 'column' }} justifyContent='space-between ' alignItems='center'>
                        <Stack spacing={1} direction='column' textAlign={{ xs: 'center', sm: 'start' }}>
                            <Typography variant='h2' color='Tertiary'>Current Balance</Typography>
                            <Stack pt={{ xs: 2, sm: 0 }} rowGap={1} spacing={{ xs: 1, sm: 3 }} direction={{ xs: 'column', md: "row" }} alignItems='center' >
                                <Typography variant='h1' sx={{ display: 'flex' }}><Box componet='span' pr={2} color='Tertiary'>$</Box> 1,474.91</Typography>
                                <Stack spacing={1} direction='row' alignItems='center' justifyContent='center' sx={{
                                    boxShadow: '0 0 3px 0 rgba(0,0,0,0.1)',
                                    width: "130px",
                                    height: "50px",
                                    borderRadius: "23px"
                                }}>
                                    <IconButton sx={{ backgroundColor: '#DCEFD2', width: '32px', height: '32px' }}>
                                        <DollarSymbolIcon />
                                    </IconButton>
                                    <Typography variant='h5'>USD</Typography>
                                    <Box color="#93B0C8" pt='5px'><KeyboardArrowDownIcon /></Box>
                                </Stack>
                            </Stack>
                            <Stack spacing={3} direction='row' alignItems='center' justifyContent={{ xs: 'center', md: 'start' }} paddingTop={{ xs: '15px', sm: "8px" }}>
                                <Typography variant='h5' color='#50AD20'> + $301.93</Typography>
                                <Box sx={{
                                    padding: "7px 6px 7px 6px",
                                    borderRadius: "4px",
                                    backgroundColor: '#F0F7FD',
                                    width: '34px',
                                    height: "15px",
                                    textAlign: "center",
                                    color: '#1F3048',
                                    display: "flex",
                                    alignItems: 'center',
                                    justifyContent: 'center'

                                }}> <Typography variant='h6'>  24h </Typography></Box>
                                <Typography variant='h5' color='#50AD20' display='flex' > <ArrowDropUpIcon sx={{ marginRight: '5px' }} />  25.69</Typography>
                            </Stack>
                        </Stack>
                        <Stack rowGap={2} direction={{ lg: 'row', md: 'row', xs: 'row' }} alignItems='center' justifyContent='center' sx={{
                            '@media(max-width:1000px)': {
                                flexDirection: 'column !important'
                            }, '@media(max-width:900px)': {
                                flexDirection: 'row !important'
                            }
                        }} spacing={2} paddingTop={{ xs: '24px', md: "0px" }}>
                            <Button variant='outlined' >
                                <Typography variant='h5'>More</Typography>
                                <Typography paddingRight='7px' paddingBottom='20px' height='7px' fontSize='16px' fontWeight='1000'>...</Typography>
                            </Button>
                            <Button variant="Standard"> Add Capital <IconButton sx={{ padding: "10px 0 12px 10px", width: '31px' }}> <AddButtonIcon /></IconButton></Button>

                        </Stack>
                    </Stack>
                    <Stack direction={{ xs: 'column', md: 'row' }} mt={1} justifyContent='space-between' alignItems='center' paddingTop={{ xs: '24px', md: "0px" }}>
                        <Typography variant='h5_2' mx='20px' color='#8A8C8F'>Total Portfolio Overview</Typography>
                        <Stack direction='row' spacing={1} paddingTop={{ xs: '24px', md: "0px" }}>
                            <Button sx={{ color: 'Title_Text_color', backgroundColor: 'Bg_color' }}>6 Months</Button>
                            <Button variant='Standard'>1 Year</Button>
                        </Stack>
                    </Stack>
                    <Box component='div' width='100%' height='100%' mt={4}>
                        {/* <Paper className="app" > */}
                        <Box component='div' id="chart" width='100%'></Box>
                        {/* </Paper> */}
                    </Box>
                </Paper>
                <Paper sx={{ backgroundColor: 'white', marginTop: '40px', padding: '20px', borderRadius: '20px', height: 'auto' }}>
                    <Stack direction={{ xs: 'column', md: "row" }} justifyContent='space-between' padding='10px 24px 20px 24px' sx={{ position: 'relative' }}>
                        <Typography variant='h3' color='Heading_color' textAlign={{ xs: 'center', md: 'start' }}>My Portfolio</Typography>
                        <Stack direction='row' justifyContent={{ xs: 'center', md: 'none' }} spacing={2} marginTop={{ xs: '35px', md: "0px" }}>
                            <Button sx={{ color: 'Title_Text_color', backgroundColor: 'Bg_color', fontSize: '18px' }}>... More</Button>
                            <Button variant="Standard"> Add Product <IconButton sx={{ padding: "10px 0 12px 10px", width: '31px' }}> <AddButtonIcon /></IconButton></Button>

                        </Stack>
                        <IconButton component='div' sx={{
                            position: 'absolute',
                            zIndex: '1',
                            bottom: '-40px',
                            right: '-10px',
                            width: '45px',
                            height: '45px',
                            backgroundColor: 'white',
                            borderRadius: '100px',
                            display: { xs: 'flex', sm: 'none' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                            color: '#3F7DBC',
                            transition: '.3s'
                        }}>
                            <ArrowForwardIosIcon sx={{
                                fontSize: '1.3rem'
                                // paddingLeft: '8px'
                            }} />

                        </IconButton>
                    </Stack>

                    <Box component='div' mt={4} sx={{ paddingLeft: { xs: '0px', sm: '0px' } }}>
                        <TableContainer sx={{ backgroundColor: 'White_color', borderRadius: '10px' }}>
                            <Table aria-label="simple table">
                                <TableHead >
                                    <StyledTableRow sx={{ '.MuiTableCell-root': { color: '#BCC1C8' } }}>
                                        <StyledTableCell>Name</StyledTableCell>
                                        <StyledTableCell>PRICE</StyledTableCell>
                                        <StyledTableCell>24H</StyledTableCell>
                                        <StyledTableCell>HOLDINGS</StyledTableCell>
                                        <StyledTableCell sx={{wordbreak: 'keep-all !important'}}>AVG. BUY PRICE</StyledTableCell>
                                        <StyledTableCell>PROFIT/LOSS</StyledTableCell>
                                        <StyledTableCell>ACTIONS</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {[1, 1, 1, 1, 1].map((itm, index) => (
                                        <StyledTableRow >

                                            <StyledTableCell >
                                                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                                    <Box component='img' mr={1.3} src={BitCoinIcon} sx={{ width: '23px', height: '23px' }}></Box>
                                                    Bitcoin (BTC)
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell>$ 426.67</StyledTableCell>
                                            <StyledTableCell sx={{ color: '#47A663' }}>+{' '}1.31%</StyledTableCell>
                                            <StyledTableCell >$ 200.63</StyledTableCell>
                                            <StyledTableCell>$ 411.39</StyledTableCell>
                                            <StyledTableCell > + $7.14 <Typography color='#47A663' mt={1}>3.67%</Typography></StyledTableCell>
                                            <StyledTableCell >
                                                <IconButton><PlusGreyIcon /></IconButton>
                                                <IconButton> <DotsIcon /> </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Paper>
            </Box >
        </>
    )
}
