import React, { useEffect } from 'react'
import axios from 'axios';




import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import List from "@mui/material/List";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { borderRadius } from '@mui/system';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



import mainimg from "../img/news/main.jpg";
import crd1 from "../img/news/crd1.jpeg";
import crd2 from "../img/news/crd2.jpeg";
import crd3 from "../img/news/crd3.webp";
import crd4 from "../img/news/crd4.jpeg";
import crd5 from "../img/news/crd5.jpeg";
import crd6 from "../img/news/crd-6.webp";
import crd7 from "../img/news/crd7.webp";
import crd8 from "../img/news/crd8.jpeg";




const LatestNewsData = [
    {
        id: 1,
        img: crd1,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },
    {
        id: 1,
        img: crd2,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },
    {
        id: 1,
        img: crd3,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },
    {
        id: 1,
        img: crd4,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },
    {
        id: 1,
        img: crd5,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },
    {
        id: 1,
        img: crd6,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },
    {
        id: 1,
        img: crd7,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },
    {
        id: 1,
        img: crd8,
        des: "News Title Lorem Ipsum Dolor Sit Amet"
    },

]


export default function News() {
    useEffect(() => {
        fetchData()
    }, [])
    async function fetchData() {
        try {
            const response = await axios.get('http://188.166.206.39:9000/v1/news', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : null}`,
                },
            });
            // Handle the response data as needed
        } catch (error) {
            // Handle errors, e.g., show an error message to the user
        }
    }
    return (
        <>
            <Box backgroundColor='#F0F7FD' height='auto'>
                <Box sx={{

                    backgroundColor: 'white',
                    padding: {
                        sm: '20px 45px 35px 45px',
                        xs: '20px 15px 35px 15px'
                    },
                    borderRadius: "18px"

                }}>
                    <Typography sx={{
                        fontSize: "35px",
                        fontWeight: '500',
                    }}> Hot Topics</Typography>

                    <Card sx={{
                        display: 'flex',
                        width: '100%',
                        mt: '17px',
                        boxShadow: 'none'


                    }}>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={8}>
                                <Box lg={8} component='div' sx={{
                                    position: 'relative',
                                    width: '100%'
                                }}>
                                    <CardMedia
                                        component='img'
                                        sx={{
                                            maxHeight: '350px',
                                            height: "100%",
                                            width: "100%",
                                            borderRadius: '10px',

                                        }}
                                        image={mainimg}
                                    />
                                    <Box sx={{
                                        position: 'absolute',
                                        bottom: '7%',
                                        left: '5%',
                                        width: '300px',
                                        color: 'white',

                                    }}>
                                        <Typography variant='h2' sx={{
                                            // fontSize: '27px'
                                        }}>   Massa tortor nibh nulla condimentum imperdiet scelerisque...
                                        </Typography>
                                        <Stack direction='row' spacing={4} mt='15px'>
                                            <Typography variant='h7' color='#F8F8F8' >2 Hours Ago</Typography>
                                            <Typography variant='h7' color='#F8F8F8'> CNN INDONESIA</Typography>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item lg={4} sx={{ display: { sm: 'block', xs: 'none' } }}>
                                <CardContent sx={{
                                }}>
                                    <Typography sx={{
                                        color: '#444444',
                                        fontSize: '18px',
                                        lineHeight: '30px',
                                        letterSpacing: '.5px'
                                    }}> <Box component='span' fontSize={30}> Nisi </Box>, sagittis aliquet sit rutrum. Nunc,
                                        id vestibulum quam ornare adipiscing. Pellentesque
                                        sed turpis nunc gravida pharetra, sit nec vivamus
                                        pharetra. Velit, dui, egestas nisi, elementum mattis
                                        mauris, magnis. Massa tortor nibh nulla condimentum
                                        imperdiet scelerisque...<Box component='span' sx={{
                                            fontSize: '19px'
                                        }}> read more </Box></Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Box>
                <Box mt={5} sx={{
                    backgroundColor: 'white',
                    borderRadius: '18px',
                    padding: { sm: '20px', xs: '20px 0px 20px 0px' },
                    position: "relative",
                    marginBottom: '30px',
                    position: 'relative'


                }}>
                    <Typography variant='h2' px={4} py={1}>Latest News</Typography>

                    <Grid container mt='15px' sx={{
                        display: 'flex',
                        justifyContent: "center"
                    }}>

                        {LatestNewsData.map((itm) => {

                            return (
                                <Grid xs={12} sm={8} md={4} lg={3} sx={{ minWidth: '280px' }}>
                                    <Card boxShadow='none' sx={{
                                        height: 'auto',
                                        boxShadow: "none",
                                        padding: {
                                            sm: '20px 35px 20px 35px',
                                            xs: '10px 35px 10px 35px'
                                        }
                                    }}>
                                        <CardMedia component='img' sx={{
                                            height: "150px",
                                            borderRadius: '7px'
                                        }}
                                            image={itm.img}
                                        />
                                        <CardContent sx={{
                                            padding: '0px'
                                        }}>
                                            <Typography variant='h5' mt='10px' sx={{

                                            }}> {itm.des}</Typography>
                                            <Stack direction='row' spacing={3} mt='16px' fontSize='10px'>
                                                <Typography fontSize='11px' color='#949494'>1 Hour Ago </Typography>
                                                <Typography fontSize='11px' color='#949494'>CNN Indonesia</Typography>
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        })}

                    </Grid>

                    <IconButton component='div' sx={{
                        position: 'absolute',
                        zIndex: '1',
                        bottom: '-20px',
                        right: '48%',
                        width: '50px',
                        height: '50px',
                        backgroundColor: 'white',
                        borderRadius: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                        color: '#3F7DBC',
                        transition: '.3s'
                    }}>
                        <KeyboardArrowDownIcon sx={{
                            fontSize: '2rem'
                            // paddingLeft: '8px'
                        }} />

                    </IconButton>
                </Box>
            </Box>
        </>
    )
}
