export const settingsMenuData= [
    {name:'User Account', key:'user_account'},
    {name:'Bank Information', key:'bank_information'},
    {name:'Wallets', key:'wallets'},
    {name:'Deposits', key:'deposits'},
    {name:'KYC + DocuSign', key:'kyc_docusign'},
    {name:'Subscription Plan', key:'subscription_plan'},
    {name:'Default Currency', key:'default_currency'},
    {name:'Help', key:'help'},
    {name:'Logout', key:'logout'},
]