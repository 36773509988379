import React, { useState } from 'react'
import {Box, Typography, Button, IconButton, Chip} from "@mui/material";
import { ReactComponent as EditIcon } from '../../img/edit_pencil.svg';
import { ReactComponent as AvatarIcon } from '../../img/avatar_temp.svg';
import { ReactComponent as BankIcon } from '../../img/bankIcon.svg';
import { MdAdd } from "react-icons/md";

export default function ConnectedAccounts ()  {
    return(
        <Box sx={{ margin:'10px', borderRadius:'18px', padding:'20px', display:'flex', gap:'5px',  backgroundColor:' #EAF4FF66', flexDirection:'column'}}>
        {/* <Box component='img' src={localUserData?.picture} sx={{width:'100px', height:'100px', borderRadius:'50%'}}/> */}
          <Box sx={{display:'flex', flexDirection:'column', gap:'5px', justifyContent:'center'}}>
            <Typography sx={{fontWeight:500, fontSize:'22px', color:'#1F3048',  lineHeight:'37px'}}>Connected Accounts</Typography>
          </Box>

        <Box sx={{display:'flex', justifyContent:'space-between', mb:10}}>
            <Box sx={{display:'flex', gap:'15px'}}>
                <BankIcon style={{margin:'12px 0'}}/>
                <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center'}}>
                    <Box sx={{}}>
                    <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'15px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>DBS Bank Limited</Typography>
                    <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'30px'}}>DBS89 ING 909023124</Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{flex:1, display:'flex', justifyContent:'flex-end'}}>
                <Chip
                    sx={{fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px',backgroundColor:'white', padding:'15px 5px',border:'none'}}
                    label="Disconnect"
                    onClick={() => {}}
                    variant="outlined"
                />
            </Box>
        </Box>
        <Box sx={{display:'flex', justifyContent:'center'}}>
            <Button sx={{  backgroundColor: '#3F7DBC', color:'white', width:'400px', '&:hover': {
                backgroundColor:'#3F7DBC'
            }}} variant="contained" endIcon={<MdAdd />}>
                <Typography sx={{textTransform:'none', fontSize:'16px', lineHeight:'22px', fontWeight:500, padding:'10px', borderRadius:"8px"}}>Add Bank Account</Typography>
            </Button>
        </Box>
      
    </Box>
    )
}