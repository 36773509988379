import { GlobalActionTypes,LoginActionTypes } from "./GlobalActionTypes";
const initialState = {
    isLoading: false,
    response: [],
    responseData: "",
    error: "",
}

export const SnackBarReducer = (state = { type: "", message: "" }, { type, payload }) => {
    switch (type) {
        case GlobalActionTypes.SetSnackBar:
            return { type: payload.type, message: payload.message }
        default:
            return state;
    }
}
export const LoginReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LoginActionTypes.loginInitiate:
            return { ...state, isLoading: true };
        case LoginActionTypes.loginSuccess:
            return { ...state, response: payload, isLoading: false };
        case LoginActionTypes.loginFailed:
            return { ...state, responseData: payload, isLoading: false };
        default:
            return state;
    }
}