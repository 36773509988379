const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
const onlyNumbers = /^\d+$/
const alphabetsWhiteSpace = /^[a-zA-Z ]*$/
const alphaNumericUnderscore = /^[A-Za-z0-9_]+$/
const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/


export const emailValidator = (email) => {
    if (emailRegex.test(email)) {
        return true;
    } else {
        return false;
    }
}

export const numberOnChangeValidator = (no) => {
    if (onlyNumbers.test(no)) {
        return true;
    } else {
        return false;
    }
}

export const alphabetsWhiteSpaceValidator = (text) => {
    if (alphabetsWhiteSpace.test(text)) {
        return true;
    } else {
        return false;
    }
}

export const alphaNumericUnderscoreValidator = (text) => {
    if (alphaNumericUnderscore.test(text)) {
        return true;
    } else {
        return false;
    }
}

export const passwordValidator = (password) => {
    if (passwordRegex.test(password)) {
        return true;
    } else {
        return false;
    }
}

export const ageEighteenValidator = (date) => {
    let birthday = new Date(date);
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch

    if (Math.abs(ageDate.getUTCFullYear() - 1970) >= 18) {
        return true;
    } else {
        return false;
    }
}