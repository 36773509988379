import React, { Fragment } from 'react'
import MessageIcon from '../../img/MessageIcon.png';
import { Box, Typography, IconButton, Stack, Pagination, PaginationItem } from '@mui/material';
import { ReactComponent as MarkAsReadIcon } from '../../img/markAsReadIcon.svg';
import { ReactComponent as MarkAsUnread } from '../../img/markAsUnread.svg';
import { ReactComponent as MarkAsReadDisable } from '../../img/markAsReadDisable.svg';
import { ReactComponent as MarkAsUnreadEnable } from '../../img/markAsUnreadEnable.svg';
import { ReactComponent as RemoveNotifitcationIcon } from '../../img/removeNotifitcationIcon.svg';

import { ReactComponent as PaginationForwardIcon } from '../../img/PaginationForwardIcon.svg';
import { ReactComponent as PaginationBackArrowIcon } from '../../img/PaginationBackArrow.svg';

export default function General() {
    const generalData = [
        { id: 1, icon: MessageIcon, boldText: 'John Doe', lightText: 'has joined from your invitation and you will get your referral points.', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 2, icon: MessageIcon, boldText: 'You have turned off the email updates.', lightText: '', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 3, icon: MessageIcon, boldText: 'Your password has been successfully changed.', lightText: '', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 4, icon: MessageIcon, boldText: '', lightText: 'This notification has been read by you and now you can delete it.', time: '1 mins ago', type: 'New', markAsRead: false, markAsUnRead: true },
        { id: 5, icon: MessageIcon, boldText: 'John Doe', lightText: 'has joined from your invitation and you will get your referral points.', time: '1 mins ago', type: 'New', markAsRead: false, markAsUnRead: true },
    ]
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {generalData?.map((item, index) => (
                <Box sx={{ backgroundColor: 'white', borderRadius: index == generalData?.length - 1 ? '0 0 20px 20px' : '0px', paddingBottom: index == generalData?.length - 1 ? ' 30px' : '' }}>
                    <Box key={item.id} sx={{ display: 'flex', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' }, borderLeft: item?.markAsRead ? '8px solid #50AD20' : '10px solid #CACACA', paddingLeft: '34px', margin: '34px 34px 34px 0', gap: '30px' }}>
                        <Box component='img' sx={{ width: { xs: '80px', sm: '100px' }, height: { xs: '80px', sm: '100px' }, borderRadius: "100px", objectFit: 'cover' }} src={item.icon} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <Typography variant="h3" sx={{ letterSpacing: '.7px' }} >
                                {item.boldText != '' ? item.boldText : ""} <Box component='span' sx={{ color: '#CACACA', fontSize: { xs: '18px', md: "19px" } }}>{item.lightText != '' ? item.lightText : ""}</Box>
                            </Typography>
                            {/* <Typography variant="h4" sx={{ color: '#CACACA' }}>{item.lightText != '' ? item.lightText : ""}</Typography> */}
                            {/* <Box sx={{ display: 'flex', gap: '2px' }}>
                                <Typography variant="h3" sx={{ letterSpacing: '.7px' }} >{item.boldText != '' ? item.boldText : ""}</Typography>
                                <Typography variant="h4" sx={{ color: '#CACACA' }}>{item.lightText != '' ? item.lightText : ""}</Typography>
                            </Box> */}
                            <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center', gap: '70px' }}>
                                <Typography variant='h6' sx={{ color: 'Grey', }}>{item.time}</Typography>
                                <Typography variant='h7' color='secondary' sx={{ padding: ' 3px 5px', backgroundColor: '#DBEFCD', borderRadius: '5px' }}>{item.type}</Typography>
                            </Box>
                            <Stack direction={{ xs: 'column', md: 'row' }} columnGap={5} rowGap={2.2} mt={1}>
                                <Stack direction='row'>
                                    {item?.markAsRead ? <MarkAsReadIcon /> : <MarkAsReadDisable />}
                                    <Typography variant="h5_2" color={item?.markAsRead ? 'primary' : 'Title_Text_color'} sx={{ ml: 1 }}>Mark as Read</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    {item?.markAsUnRead ? <MarkAsUnreadEnable /> : <MarkAsUnread />}
                                    <Typography variant="h5_2" color={item?.markAsUnRead ? 'primary' : 'Title_Text_color'} sx={{ ml: 1 }}>Mark as Unread</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    <RemoveNotifitcationIcon />
                                    <Typography variant="h5_2" color='Title_Text_color' sx={{ ml: 1 }}>Remove Notification</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            ))
            }

            <Stack mt={4} direction='row' justifyContent='center' spacing={2}>
                <Pagination
                    count={5}
                    sx={{ '.MuiPaginationItem-circular': { backgroundColor: 'white' }, '.Mui-selected': { backgroundColor: '#3F7DBC !important', color: 'white' }, '.Mui-selected:hover': { backgroundColor: '#3F7DBC', color: 'white' } }}
                    renderItem={(item) => (
                        <PaginationItem slots={{ previous: PaginationBackArrowIcon, next: PaginationForwardIcon }}  {...item}
                        />)}
                />
            </Stack>
        </Box >
    )
}
