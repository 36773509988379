import React, { useState } from 'react'
import {Box, Typography, Button, IconButton} from "@mui/material";
import { ReactComponent as EditIcon } from '../../img/edit_pencil.svg';
import { ReactComponent as AvatarIcon } from '../../img/avatar_temp.svg';

export default function UserAddressInfo ()  {
    return(
        <Box sx={{ margin:'10px', borderRadius:'18px', padding:'20px', display:'flex', gap:'5px',  backgroundColor:' #EAF4FF66', flexDirection:'column'}}>
                <Box sx={{display:'flex', justifyContent:'space-between', width:'100%'}}>
                  <Box sx={{display:'flex', flexDirection:'column', gap:'5px', justifyContent:'center'}}>
                    <Typography sx={{fontWeight:500, fontSize:'22px', color:'#1F3048',  lineHeight:'37px'}}>Address</Typography>
                  </Box>
                  <Box sx={{display:'flex', alignSelf:'center', borderRadius:'30px', background:'white', gap:'5px', height:'40px', padding:'0 10px' }}>
                    <Typography sx={{fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px', alignSelf:'center'}}>Edit</Typography>
                    <IconButton><EditIcon /></IconButton>
                  </Box>
                </Box>

                <Box sx={{display:'flex', justifyContent:'space-between'}}>
                  <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Box sx={{ my:1.5}}>
                      <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>Country</Typography>
                      <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>Singapore</Typography>
                    </Box>
                    <Box sx={{ my:1.5}}>
                      <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>Postal Code</Typography>
                      <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>521147</Typography>
                    </Box>
                  </Box>

                  <Box sx={{display:'flex', flexDirection:'column'}}>
                    <Box sx={{ my:1.5}}>
                      <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>City / State</Typography>
                      <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>Singapore City</Typography>
                    </Box>
                    <Box sx={{ my:1.5}}>
                      <Typography sx={{letterSpacing:'2%',fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px'}}>TAX ID</Typography>
                      <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'37px'}}>A454AR5664</Typography>
                    </Box>
                  </Box>
                  <Box>
                    
                  </Box>
                </Box>
              
            </Box>
    )
}