import { GlobalActionTypes, LoginActionTypes } from "./GlobalActionTypes"
import axios from "axios"

export const SetSnackBar = (type, message) => dispatch => {
    dispatch({ type: GlobalActionTypes.SetSnackBar, payload: { type, message } })
}

export const getAuth = (data) => async (dispatch) => {
    dispatch({ type: LoginActionTypes.loginInitiate });
    const response = await axios.get("http://188.166.206.39:9000/v1/oauth/authorise")
        // console.log('resposne getauth ', response)
        if (response) {
            console.log('401 resposne getauth ', response)
            return dispatch({ type: LoginActionTypes.loginFailed })
        } else {
            return dispatch({ type: LoginActionTypes.loginFailed, payload: response })
        }
        
    
    // if (response) {
    //     console.log('201 resposne getauth ', response)
    //     dispatch({ type: LoginActionTypes.loginSuccess, payload: response.data })
    // }



}
