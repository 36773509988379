import React from 'react'
import {Box, Typography } from "@mui/material";
import Button from '@mui/material/Button';
import CopyIcon from '../../img/copy.png';

export default function WalletNeedHelp ()  {

    return(
        <Box sx={{ margin:'10px', borderRadius:'18px', padding:'20px', display:'flex', gap:'5px',  flexDirection:'column', textAlign:'center' }}>
            <Typography sx={{fontWeight:500, fontSize:'18px', color:'#CACACA',  lineHeight:'25px', letterSpacing:'5%'}}>After you deposit funds into TradeTogether’s wallet’s address. You
            will have to wait 24 to 48 hours for confirmation.</Typography>
            <Button variant='text' sx={{color:'#3F7DBC', width:'100px', alignSelf:'center', mt:1}}>Need Help?</Button>
    </Box>
    )
}