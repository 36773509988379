import React, { useState } from 'react'
import {Box, Typography, Button, IconButton, Chip} from "@mui/material";
import { ReactComponent as EditIcon } from '../../img/edit_pencil.svg';
import { ReactComponent as AvatarIcon } from '../../img/avatar_temp.svg';
import { ReactComponent as QRcodeIcon } from '../../img/walletQRcode.svg';
import { MdAdd } from "react-icons/md";

export default function QRcode ()  {
    return(
        <Box sx={{ margin:'10px', borderRadius:'18px', padding:'20px', display:'flex', gap:'5px',  backgroundColor:' #EAF4FF66', flexDirection:'column'}}>
        {/* <Box component='img' src={localUserData?.picture} sx={{width:'100px', height:'100px', borderRadius:'50%'}}/> */}
          <Box sx={{display:'flex', flexDirection:'column', gap:'5px', justifyContent:'center'}}>
            <Typography sx={{fontWeight:500, fontSize:'18px', color:'#1F3048',  lineHeight:'30px'}}>TradeTogether Wallet Information</Typography>
          </Box>

        <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', gap:'10px', mt:3}}>
            <QRcodeIcon />
            <Typography sx={{fontWeight:500, fontSize:'18px', color:'#000000',  lineHeight:'30px'}}>Scan QR Code</Typography>
        </Box>
      
    </Box>
    )
}