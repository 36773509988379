import * as React from 'react';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Crypto_prices from './pages/Crypto_prices';
import Dashboard from './pages/Dashboard'
import Overview from './pages/Overview'
import News from "./pages/News"
import Portfolio from './pages/Portfolio';
import Sumsubkyc from "./pages/Sumsubkyc"
import SignUp from './pages/signup/SignUp';

import Admin_Dashboard_news from './admin/pages/dashboard_news';
import Admin from "./admin/pages/admin_dashboard"
import AdminDashboardupcomingevents from "./admin/pages/dashboardupcomingevents"
// import Dashboard_news from './admin/dashboard_news';
import Adminlogin from "./admin/pages/adminlogin"
import Admincustomer from "./admin/pages/admin_customer"
import Settings from './pages/Settings/Settings';
import Transactions from './pages/Transactions/Transactions';
import UpcomingEvents from './pages/UpcomingEvents';
import Messages from './pages/Messages';
import { useAuth0 } from '@auth0/auth0-react';




const isAuthenticated = () => {

  return !!localStorage.getItem("Admintoken")

}

const PrivateRoute = ({ element }) => {

  return (isAuthenticated() ? element : <Navigate to="/adminlogin" />)
}



export default function App() {
  const {
    isLoading,
    error,
  } = useAuth0();
  React.useEffect(() => {
    if (isLoading) {
      console.log('laoding')
    }
    if (error) {
      console.log('erroro ',error)
    }
  },[])
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path='/' element={<Dashboard />}>
            <Route index element={<Overview />} />
            <Route path="/Cryptoprices" element={<Crypto_prices />} />
            <Route path='/News' element={<News />} />
            <Route path='/Portfolio' element={<Portfolio />} />
            <Route path='/Sumsubkyc' element={<Sumsubkyc />} />
            <Route path='/Settings' element={<Settings />} />
            <Route path='/Transactions' element={<Transactions />} />
            <Route path='/UpcomingEvents' element={<UpcomingEvents />} />
            <Route path='/Messages' element={<Messages />} />
          </Route>
        </Routes>
      </BrowserRouter>

      <BrowserRouter>
        <Routes>
          <Route path='/adminlogin' element={<Adminlogin />} />

          <Route path="/admin" element={<PrivateRoute element={<Admin />} />} >
            <Route path='/admin/dashboard_news' element={<PrivateRoute element={<Admin_Dashboard_news />} />} />
            <Route path="/admin/Dashboardupcomingevents" element={<PrivateRoute element={<AdminDashboardupcomingevents />} />} />
            <Route path="/admin/customer" element={<PrivateRoute element={<Admincustomer />} />} />
          </Route>
        </Routes>
      </BrowserRouter>



    </>
  );
}