import { Box, Button, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Typography, Drawer, Tooltip, ListItemIcon } from '@mui/material'
import React, { useEffect } from 'react'
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import CustomizedSnackbars from './Toast';
import { useDispatch, useSelector } from 'react-redux';
import { SetSnackBar } from '../redux/Global/GlobalAction';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as AvatarTTIcon } from '../img/LogoTT.svg';
import { ReactComponent as NotificationIcon } from '../img/icon_bell.svg';
import { ReactComponent as InvitationUser } from '../img/InvitationUser.svg';
import { getAuth } from '../redux/Global/GlobalAction';
import { ReactComponent as OverviewLgIcon } from "../img/MenuIcons/OverviewLg.svg"
import { ReactComponent as CryptopricesLgIcon } from "../img/MenuIcons/CryptopricesLg.svg"
import { ReactComponent as PortfolioLgIcon } from "../img/MenuIcons/PortfolioLg.svg"
import { ReactComponent as NewsLgIcon } from "../img/MenuIcons/NewsLg.svg"
import { ReactComponent as UpcomingEventsLgIcon } from "../img/MenuIcons/UpcomingEventsLg.svg"
import { ReactComponent as TransactionLgIcon } from "../img/MenuIcons/TransactionLg.svg"
import { ReactComponent as MessagesLgIcon } from "../img/MenuIcons/MessagesLg.svg"
import { ReactComponent as SettingsLgIcon } from "../img/MenuIcons/SettingsLg.svg"
import { ReactComponent as Logo } from "../img/logo.svg"
import { ReactComponent as OverviewSmIcon } from "../img/MenuIcons/OverviewSm.svg"
import { ReactComponent as CryptopricesSmIcon } from "../img/MenuIcons/CryptopricesSm.svg"
import { ReactComponent as PortfolioSmIcon } from "../img/MenuIcons/PortfolioSm.svg"
import { ReactComponent as NewsSmIcon } from "../img/MenuIcons/NewsSm.svg"
import { ReactComponent as UpcomingEventsSmIcon } from "../img/MenuIcons/UpcomingEventsSm.svg"
import { ReactComponent as TransactionSmIcon } from "../img/MenuIcons/TransactionSm.svg"
import { ReactComponent as MessagesSmIcon } from "../img/MenuIcons/MessagesSm.svg"
import { ReactComponent as SettingsSmIcon } from "../img/MenuIcons/SettingsSm.svg"
import MobileLogo from "../img/Logo.png"



const menus = [{
    id: 1,
    nav_bar_name: "Overview",
    isdrobdown: 0,
    uri_link: "/",
    icon_sm: <OverviewSmIcon />,
    icon_lg: <OverviewLgIcon />
},
{
    id: 2,
    nav_bar_name: "Crypto Prices",
    isdrobdown: 0,
    uri_link: "/Cryptoprices",
    icon_sm: <CryptopricesSmIcon />,
    icon_lg: <CryptopricesLgIcon />
},
{
    id: 3,
    nav_bar_name: "My Portfolio",
    isdrobdown: 0,
    uri_link: "/Portfolio",
    icon_sm: <PortfolioSmIcon />,
    icon_lg: <PortfolioLgIcon />
},
{
    id: 4,
    nav_bar_name: "News",
    isdrobdown: 0,
    uri_link: "/News",
    icon_sm: < NewsSmIcon />,
    icon_lg: <NewsLgIcon />
},
{
    id: 5,
    nav_bar_name: "Upcoming Events",
    isdrobdown: 0,
    uri_link: "/UpcomingEvents",
    icon_sm: <UpcomingEventsSmIcon />,
    icon_lg: <UpcomingEventsLgIcon />
},
{
    id: 6,
    nav_bar_name: "Transactions",
    isdrobdown: 0,
    uri_link: "/Transactions",
    icon_sm: <TransactionSmIcon />,
    icon_lg: <TransactionLgIcon />

},
{
    id: 7,
    nav_bar_name: "Messages",
    isdrobdown: 0,
    uri_link: "/Messages",
    icon_sm: <MessagesSmIcon />,
    icon_lg: <MessagesLgIcon />
},
{
    id: 8,
    nav_bar_name: "Settings",
    isdrobdown: 0,
    uri_link: "/Settings",
    icon_sm: <SettingsSmIcon />,
    icon_lg: <SettingsLgIcon />
},

]


const SideBar = ({ children }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation()
    const { logout, user, isAuthenticated, loginWithRedirect } = useAuth0();
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);
    const [mobileDrawer, setMobileDrawer] = useState(false);
    const [sidebaropen, setSideBarOpen] = useState(true)
    const drawerWidth = sidebaropen ? 250 : 170;
    const drawerRootWidth = sidebaropen ? 200 : 121;


    const LoginDatas = useSelector(state => state.LoginData);


    useEffect(() => {
        console.log('Authentic user', isAuthenticated, user);
        if (isAuthenticated && user) {
            console.log(localStorage.getItem('userData'));
            if (localStorage.getItem('userData') === null) {
                dispatch(SetSnackBar('success', 'Logged in Successfully!'));
                localStorage.setItem('userData', JSON.stringify(user));
                let data = JSON.parse(localStorage.getItem('userData'));
                console.log(data);
            }
        }
    }, [isAuthenticated, user]);



    const handleLogout = () => {
        localStorage.removeItem('userData')
        localStorage.removeItem('token')
        logout();
    }

    const toggleWebDrawer = () => {
        setSideBarOpen(prevValue => !prevValue);
    }

    useEffect(() => {
        const handleWindowResize = () => {
            setInnerHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });



    return (

        <Box>
            <Box sx={{ display: "flex", height: "calc(100% - 65px)", position: 'relative' }} >
                <IconButton
                    onClick={toggleWebDrawer}
                    component='div'
                    sx={{
                        display: {
                            sm: 'flex',
                            xs: 'none',
                        },
                        position: 'sticky',
                        zIndex: '1201',
                        top: '50%',
                        left: sidebaropen ? '227px' : '145px',
                        width: '50px',
                        height: '50px',
                        backgroundColor: 'white',
                        borderRadius: '100px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: '0 0 10px 0 rgba(0,0,0,0.2)',
                        color: '#3F7DBC',
                        transition: '.4s',
                    }}
                >
                    {sidebaropen ? <ArrowBackIosIcon sx={{ paddingLeft: '8px', }} /> : <ArrowForwardIosIcon />}
                </IconButton>
                <Drawer
                    sx={{
                        width: drawerRootWidth,
                        flexShrink: 0,
                        display: {
                            sm: 'block',
                            xs: 'none',
                        },
                        "& .MuiDrawer-paper": {
                            width: drawerWidth,
                            '::-webkit-scrollbar': { display: 'none' },
                            '-ms-overflow-style': 'none',
                            'scrollbarWidth': 'none',
                            overflow: 'auto',
                            transition: '.4s'
                        },
                        "& .MuiDrawer-root": {
                            width: drawerRootWidth,
                        },
                        transition: '.4s'
                    }}
                    variant="persistent"
                    anchor="left"
                    open={true}
                >
                    {sidebaropen ?

                        <>
                            <Box sx={{ height: '70px', padding: '10px 10px 10px 20px' }}  >
                                <Logo key={1} />
                            </Box>
                            <List>
                                <Typography mb={2} sx={{ fontSize: '15px', fontWeight: 600, letterSpacing: '.3px', color: '#8F97A4', textAlign: 'center' }}>Managements</Typography>
                                {menus.map((item, index) => {
                                    return (
                                        <>
                                            <NavLink to={item?.uri_link}>
                                                <ListItemButton sx={{ paddingLeft: '30px', height: '54px', ".MuiListItemText-primary": { color: location?.pathname == item?.uri_link && '#3F7DBC' }, backgroundColor: location?.pathname == item?.uri_link && '#EDF7E6', borderLeft: location?.pathname == item?.uri_link ? '6px solid #50AD20' : '6px solid white', '&:hover': { ".MuiListItemText-primary": { color: '#3F7DBC' }, backgroundColor: '#EDF7E6', borderLeft: '6px solid #50AD20' } }}>
                                                    <Tooltip >
                                                        <ListItemIcon sx={{ minWidth: '40px' }}>
                                                            {item?.icon_sm}
                                                        </ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText className='fade-in' sx={{ ".MuiTypography-root": { fontSize: '15px', fontWeight: '500 ', letterSpacing: '.7px', color: location?.pathname == item?.uri_link ? '#3F7DBC' : '#CACACA', '&:hover': { color: '#3F7DBC' } } }} primary={item?.nav_bar_name} />
                                                </ListItemButton>
                                            </NavLink>
                                            {item?.nav_bar_name == 'Transactions' &&
                                                <Typography my={2} sx={{ fontSize: '15px', fontWeight: 600, letterSpacing: '.3px', color: '#8F97A4', textAlign: 'center' }}>Support</Typography>}
                                        </>
                                    )
                                })}
                            </List>

                            <Box sx={{ margin: '40px 10px 10px 10px', display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px', backgroundColor: '#F0F7FD', borderRadius: '10px' }} >
                                <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#1F3048', lineHeight: '20px' }}>User Invitations</Typography>
                                <InvitationUser />
                                <Typography sx={{ fontWeight: 500, fontSize: '13px', color: '#8A8C8F', lineHeight: '16px', letterSpacing: '2%' }}>Joined from your invite - 4</Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: '13px', color: '#8A8C8F', lineHeight: '16px', letterSpacing: '2%' }}>Invites Sent - 10</Typography>
                                <Button sx={{
                                    fontSize: '14px', textTransform: 'none', color: 'white', backgroundColor: '#3F7DBC', borderRadius: '8px', '&:hover': {
                                        backgroundColor: '#3F7DBC'
                                    }
                                }}>Get an Invite Bonus</Button>
                                {/* <Link to='/Sumsubkyc'>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#3F7DBC',

                                    textTransform: 'none',
                                    fontSize: '15px',
                                }}
                            >
                                <Typography sx={{ color: 'white', letterSpacing: '1px' }}>
                                    KYC
                                </Typography>
                            </Button>
                        </Link> */}
                                {/* {isAuthenticated &&
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#3F7DBC',

                                    textTransform: 'none',
                                    fontSize: '15px',
                                }}
                                onClick={() => handleLogout()}
                            >
                                <Typography sx={{ color: 'white', letterSpacing: '1px' }}>
                                    Logout
                                </Typography>
                            </Button>
                        } */}
                            </Box>
                            <hr style={{ color: '#CACACA' }} />
                            <Box sx={{ padding: '0 20px 20px 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <Typography sx={{ fontWeight: 500, fontSize: '13px', color: '#1F3048', lineHeight: '16px', letterSpacing: '2%' }}>© TradeTogether. 2023</Typography>
                                <Typography sx={{ fontWeight: 500, fontSize: '10px', color: '#8A8C8F', lineHeight: '12px', letterSpacing: '5%' }}>TradeTogether is the platform for trading and exchanging currencies.</Typography>
                                <Box sx={{ display: 'flex', gap: '10px' }}>
                                    <Typography sx={{ cursor: 'pointer', color: '#93B0C8', letterSpacing: '5%', fontSize: '10px', lineHeight: '12px', fontWeight: '500' }}>Terms & Condition</Typography>
                                    <Typography sx={{ color: 'black', fontSize: '20px', marginTop: '-15px' }}>.</Typography>
                                    <Typography sx={{ cursor: 'pointer', color: '#93B0C8', letterSpacing: '5%', fontSize: '10px', lineHeight: '12px', fontWeight: '500' }}>Privacy Policy</Typography>
                                </Box>
                            </Box>
                        </>
                        :
                        <>
                            <Box my={1} sx={{ height: '60px', padding: '8px 8px 6px 8px' }} >
                                <Logo key={1} width='127px !important' height='33px !important' />
                            </Box>

                            <List>
                                <Typography variant="h5" mb={2} sx={{ color: '#BCC1C8', textAlign: 'center' }}>Managements</Typography>
                                {menus.map((item, index) => {
                                    return (
                                        <>
                                            <Box mt={2}>
                                                <NavLink to={item?.uri_link}>
                                                    <ListItemButton sx={{ mx: !(location?.pathname == item?.uri_link) && 4, borderLeft: location?.pathname == item?.uri_link ? '6px solid #50AD20' : '6px solid white', borderRadius: !(location?.pathname == item?.uri_link) && '13px', Width: '60px', height: '75px', backgroundColor: location?.pathname == item?.uri_link ? '#EDF7E6' : '#F0F7FD', display: 'flex', justifyContent: 'center', '&:hover': { borderLeft: '6px solid #50AD20', borderRadius: '0', backgroundColor: '#EDF7E6', mx: 0 } }}>
                                                        <Tooltip >
                                                            <ListItemIcon sx={{ justifyContent: 'center' }}>
                                                                {item?.icon_lg}
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                    </ListItemButton>
                                                </NavLink>
                                            </Box>
                                            {item?.nav_bar_name == 'Transactions' &&
                                                <Typography my={2} variant="h5" sx={{ color: '#BCC1C8', textAlign: 'center' }}>Support</Typography>
                                            } </>
                                    )
                                })}
                            </List>
                        </>
                    }
                </Drawer >

                <Box sx={{ overflow: "auto", width: "100%", "::-webkit-scrollbar": { display: "none" } }} >
                    <Box
                        sx={{
                            height: '70px',
                            width: '100%',
                            display: {
                                sm: 'flex',
                                xs: 'none',
                            },
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Typography variant='h2' color='inherit' noWrap sx={{ flexGrow: 1, paddingLeft: '25px' }}>Welcome Benjamin,</Typography>
                        <Box color='inherit' sx={{ marginRight: '20px', display: 'flex', alignItems: 'center', }}>
                            <IconButton sx={{ backgroundColor: '#F0F7FD', '&:hover': { backgroundColor: 'Bg_color', }, }}   >
                                <NotificationIcon />
                            </IconButton>
                        </Box>

                        {!isAuthenticated && (
                            <Box sx={{ padding: '0 10px', display: 'flex', alignItems: 'center', gap: '10px', }} >
                                <Button sx={{ textTransform: 'none', }} variant='contained' onClick={() => navigate('/signup')} >  SignUp</Button>
                                <Button sx={{ textTransform: 'none' }} onClick={() => loginWithRedirect()}
                                    // onClick={() => dispatch(getAuth())}
                                    variant='contained' >
                                    Login
                                </Button>
                            </Box>
                        )}

                        <Box
                            color='inherit'
                            sx={{
                                backgroundColor: '#F0F7FD',
                                padding: '5px 10px',
                                marginRight: '25px',
                                borderRadius: '10px',
                                display: {
                                    sm: 'flex',
                                    xs: 'none',
                                },
                                alignItems: 'center',
                                maxWidth: '150px',
                            }}
                            variant='h5'
                        >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    width: '33px',
                                    height: '33px',
                                    borderRadius: '200px',
                                    color: '#50AD20',
                                }}
                                fontSize='20px'
                                fontWeight='500'
                            >
                                {' '}
                                <Box color='#3F7DBC' mr='2px'> T</Box> T
                            </Typography>
                            <Typography variant='h5' pl={1.5} sx={{ textTransform: 'capitalize', fontSize: '0.875rem', fontWeight: 600, }} noWrap  >
                                {user?.nickname ? user.nickname : ''}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Mobile Appbar   */}

                    <Box px={1.5} sx={{ height: '70px', display: { sm: 'none', xs: 'flex', }, flexDirection: 'row', justifyContent: 'space-between', alignItems: "center" }} >
                        <Box color='inherit' noWrap sx={{ flexGrow: 1 }} >
                            <IconButton
                                sx={{
                                    // "&:hover": {
                                    backgroundColor: 'Bg_color',
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '100px',
                                    fontSize: '2rem',
                                    color: '#3F7DBC',
                                    // },
                                }}
                                onClick={() => setMobileDrawer(true)}
                            >
                                <MenuIcon fontSize='2.2rem' />
                            </IconButton>
                        </Box>
                        <Box color='inherit' sx={{ marginRight: '20px', }} >
                            <IconButton
                                sx={{
                                    // "&:hover": {
                                    backgroundColor: 'Bg_color',
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '100px',
                                    fontSize: '2rem',
                                    color: '#3F7DBC',
                                    // },
                                }}
                            >
                                <NotificationsNoneIcon fontSize='2.2rem' />
                            </IconButton>
                        </Box>
                        <Box color='inherit' sx={{ borderRadius: '10px', display: 'flex', alignItems: 'center', }} >
                            <Typography
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#F0F7FD',
                                    width: '48px',
                                    height: '48px',
                                    borderRadius: '200px',
                                    color: '#50AD20',
                                }}
                                variant='h6'
                                fontSize='23px'
                                fontWeight='600'
                            >
                                {' '}
                                <Box color='#3F7DBC' mr='2px'> T</Box> T
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ padding: { xs: ' 35px 7px', sm: '35px 20px', md: '35px' }, backgroundColor: '#F0F7FD' }}>
                        {children}
                        <Typography variant='h5' mt='60px' color='primary' sx={{ textAlign: 'center', display: { xs: 'block', sm: 'none' } }}>Terms & Conditions   •   Privacy Policy</Typography>
                    </Box>
                </Box>
            </Box >

            {/* MobileDrawer */}

            <Drawer
                sx={{
                    // display: { xs: "-moz-initial", sm: "none" },

                    width: drawerRootWidth,
                    flexShrink: 0,
                    display: {
                        sm: 'none',
                        xs: 'block',
                    },
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        '::-webkit-scrollbar': { display: 'none' },
                        '-ms-overflow-style': 'none',
                        'scrollbarWidth': 'none',
                        overflow: 'auto',
                        transition: '.4s'
                    },
                    "& .MuiDrawer-root": {
                        width: drawerRootWidth,
                    },
                    transition: '.4s'
                }}

                variant="temporary"
                anchor="left"
                open={mobileDrawer}
                onClose={() => setMobileDrawer(false)}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "270px",
                        borderRight: "2px solid #E5E7EB",
                        minHeight: `${innerHeight - 66}px`,
                    }}
                >
                    <Box
                        sx={{
                            height: "100%",
                            overflowY: "auto",
                            overflowX: "hidden",
                            "&::-webkit-scrollbar": { width: "1.5px" },
                            "&::-webkit-scrollbar-track": {
                                boxShadow: " inset 0 0 1.5px #D3D3D3",
                            },
                        }}
                    >
                        <Box component='img' src={MobileLogo} sx={{ height: '57px', width: drawerRootWidth, padding: '15px 10px 10px 20px' }}  >
                        </Box>
                        <List>
                            <Typography mb={2} sx={{ fontSize: '15px', fontWeight: 600, letterSpacing: '.3px', color: '#8F97A4', textAlign: 'center' }}>Managements</Typography>
                            {menus.map((item, index) => {
                                return (
                                    <>
                                        <NavLink to={item?.uri_link} onClick={() => setMobileDrawer(false)}>
                                            <ListItemButton sx={{ paddingLeft: '30px', height: '54px', ".MuiListItemText-primary": { color: location?.pathname == item?.uri_link && '#3F7DBC' }, backgroundColor: location?.pathname == item?.uri_link && '#EDF7E6', borderLeft: location?.pathname == item?.uri_link ? '6px solid #50AD20' : '6px solid white', '&:hover': { ".MuiListItemText-primary": { color: '#3F7DBC' }, backgroundColor: '#EDF7E6', borderLeft: '6px solid #50AD20' } }}>
                                                <Tooltip >
                                                    <ListItemIcon sx={{ minWidth: '40px' }}>
                                                        {item?.icon_sm}
                                                    </ListItemIcon>
                                                </Tooltip>
                                                <ListItemText className='fade-in' sx={{ ".MuiTypography-root": { fontSize: '15px', fontWeight: '500 ', letterSpacing: '.7px', color: location?.pathname == item?.uri_link ? '#3F7DBC' : '#CACACA', '&:hover': { color: '#3F7DBC' } } }} primary={item?.nav_bar_name} />
                                            </ListItemButton>
                                        </NavLink>
                                        {item?.nav_bar_name == 'Transactions' &&
                                            <Typography my={2} sx={{ fontSize: '15px', fontWeight: 600, letterSpacing: '.3px', color: '#8F97A4', textAlign: 'center' }}>Support</Typography>
                                        }
                                    </>
                                )
                            })}
                        </List>


                        <Box sx={{ margin: '40px 10px 10px 10px', display: 'flex', flexDirection: 'column', padding: '20px', gap: '10px', backgroundColor: '#F0F7FD', borderRadius: '10px' }} >
                            <Typography sx={{ fontWeight: 500, fontSize: '16px', color: '#1F3048', lineHeight: '20px' }}>User Invitations</Typography>
                            <InvitationUser />
                            <Typography sx={{ fontWeight: 500, fontSize: '13px', color: '#8A8C8F', lineHeight: '16px', letterSpacing: '2%' }}>Joined from your invite - 4</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: '13px', color: '#8A8C8F', lineHeight: '16px', letterSpacing: '2%' }}>Invites Sent - 10</Typography>
                            <Button sx={{
                                fontSize: '14px', textTransform: 'none', color: 'white', backgroundColor: '#3F7DBC', borderRadius: '8px', '&:hover': {
                                    backgroundColor: '#3F7DBC'
                                }
                            }}>Get an Invite Bonus</Button>
                            {/* <Link to='/Sumsubkyc'>
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#3F7DBC',

                                    textTransform: 'none',
                                    fontSize: '15px',
                                }}
                            >
                                <Typography sx={{ color: 'white', letterSpacing: '1px' }}>
                                    KYC
                                </Typography>
                            </Button>
                        </Link> */}
                            {/* {isAuthenticated &&
                            <Button
                                variant='contained'
                                sx={{
                                    backgroundColor: '#3F7DBC',

                                    textTransform: 'none',
                                    fontSize: '15px',
                                }}
                                onClick={() => handleLogout()}
                            >
                                <Typography sx={{ color: 'white', letterSpacing: '1px' }}>
                                    Logout
                                </Typography>
                            </Button>
                        } */}
                        </Box>
                        <hr style={{ color: '#CACACA' }} />
                        <Box sx={{ padding: '0 20px 20px 20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <Typography sx={{ fontWeight: 500, fontSize: '13px', color: '#1F3048', lineHeight: '16px', letterSpacing: '2%' }}>© TradeTogether. 2023</Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: '10px', color: '#8A8C8F', lineHeight: '12px', letterSpacing: '5%' }}>TradeTogether is the platform for trading and exchanging currencies.</Typography>
                            <Box sx={{ display: 'flex', gap: '10px' }}>
                                <Typography sx={{ cursor: 'pointer', color: '#93B0C8', letterSpacing: '5%', fontSize: '10px', lineHeight: '12px', fontWeight: '500' }}>Terms & Condition</Typography>
                                <Typography sx={{ color: 'black', fontSize: '20px', marginTop: '-15px' }}>.</Typography>
                                <Typography sx={{ cursor: 'pointer', color: '#93B0C8', letterSpacing: '5%', fontSize: '10px', lineHeight: '12px', fontWeight: '500' }}>Privacy Policy</Typography>
                            </Box>
                        </Box>
                    </Box >
                </Box >
            </Drawer>
        </Box >

    )
}

export default SideBar