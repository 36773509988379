import React from 'react';
import ReactDOM from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import store from './redux/store';
import defaultTheme from './Theme'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={defaultTheme}>
    <Provider store={store}>
      <Auth0Provider
        // domain={process.env.REACT_APP_AUTH0_DOMAIN}
        // clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        domain='trade-together.eu.auth0.com'
        clientId='xblmH6pBE2Pvkrj2PdLelIqgdLaW2Hea'
        authorizationParams={{
          redirect_uri: 'http://188.166.206.39:9000/v1/oauth/callback',
          audience: "https://auth0-tradetogether/v2"
        }}
        scope="openid profile email"
      >
        <App />
      </Auth0Provider>
    </Provider>
  </ThemeProvider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
