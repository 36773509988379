import React, { useState, useRef } from 'react'
import { Box, Modal, Button, Typography, IconButton, Popper, Fade, Paper } from '@mui/material'
import { MdAdd } from "react-icons/md";
import { FaMinus } from "react-icons/fa6";
import { ReactComponent as BankDepositIcon } from '../../img/bankDepositIcon.svg';
import { ReactComponent as WalletDeposit } from '../../img/walletDeposit.svg';
import { ReactComponent as ModalCloseIcon } from '../../img/modalCloseIcon.svg';
import { ReactComponent as BankIcon } from '../../img/bankIcon.svg';
import CopyIcon from '../../img/copy.png';
import CopyImgIcon from '../../img/copyImg.png';
import WalletAddress from '../../components/Wallet/WalletAddress';

import { ReactComponent as QRcodeIcon } from '../../img/walletQRcode.svg';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: "80%", sm: '70%', md: '50%' },
  maxHeight: '80vh',

  // height:'50%',
  bgcolor: 'background.paper',
  borderRadius: '20px',
  // border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function ChildModal() {
  const [open, setOpen] = React.useState(false);
  const [openWallet, setOpenWallet] = React.useState(false);
  const [openSnackAccountNumber, setOpenSnackAccountNumber] = useState(false);
  const [openSnackReferenceNumber, setOpenReferenceNumber] = useState(false);
  const [openWalletInfo, setOpenWalletInfo] = useState(false);
  const accountNumber = "DBS89 ING 909023124";
  const referenceCode = "22331100";
  const walletAddress = "3K9CKsePi...Df5NfQh7iK";
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const anchorRef3 = useRef(null);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(accountNumber);
      setOpenSnackAccountNumber(true);
      setTimeout(() => {
        setOpenSnackAccountNumber(false)
      }, 3000)
    } catch (err) {

    }

  }

  const copyToClipboard2 = async () => {
    try {
      await navigator.clipboard.writeText(referenceCode);
      setOpenReferenceNumber(true);
      setTimeout(() => {
        setOpenReferenceNumber(false)
      }, 3000)
    } catch (err) {

    }

  }

  const copyToClipboard3 = async () => {
    try {
      await navigator.clipboard.writeText(walletAddress);
      setOpenWalletInfo(true);
      setTimeout(() => {
        setOpenWalletInfo(false)
      }, 3000)
    } catch (err) {

    }

  }



  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenWallet = () => {
    setOpenWallet(true);
  };
  const handleCloseWallet = () => {
    setOpenWallet(false);
  };

  return (
    <React.Fragment>
      {/* <Button onClick={handleOpen}>Open Child Modal</Button> */}
      <Box columnGap={2} rowGap={2} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Button onClick={handleOpen} variant='Standard' sx={{ padding: "30px !important" }}><BankDepositIcon style={{ width: '45px !important' }} /><Typography variant='h3' ml={1} fontWeight='400 !important'>Bank Deposit</Typography></Button>
        <Button onClick={handleOpenWallet} variant='Standard' sx={{ padding: "30px !important", backgroundColor: '#F0F7FD', }} startIcon={<WalletDeposit />} ><Typography variant='h3' ml={1} color='Heading_color' fontWeight='400 !important'>Wallet Deposit</Typography></Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        {/* <Box sx={{ ...style }}>
          <Box sx={{ ...style, display: 'flex', flexDirection: 'column', width:'90%',alignItems: 'center',  justifyContent:'center',gap: '30px'}}> */}

        <Box sx={{
          ...style, display: 'flex',
          overflowY: 'auto',
          '::-webkit-scrollbar': { display: 'none' },
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', }}>
              <Typography variant='h1_A' mr={5}>TradeTogether Bank Details</Typography>
              <Button onClick={handleClose} sx={{ position: 'absolute', top: '15px', right: '10px', width: '100px' }}><ModalCloseIcon style={{}} /></Button>
              <Box mt={3} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', gap: '25px', borderRadius: '15px', padding: '20px 20px', backgroundColor: '#EAF4FF66' }}>
                <Box sx={{ display: 'flex', gap: '40px', }}>
                  <BankIcon />
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '23px', letterSpacing: '2%', color: '#8A8C8F' }}>Account Title</Typography>
                    <Typography variant='h3'>TradeTogether</Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '40px', width: '100%', justifyContent: 'space-between' }}>

                  <Box sx={{ display: 'flex', gap: '40px' }}>
                    <BankIcon />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1 }}>
                      <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '23px', letterSpacing: '2%', color: '#8A8C8F' }}>Account Number</Typography>
                      <Typography variant='h3'>{accountNumber}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', }}>
                    <Button ref={anchorRef1} onClick={copyToClipboard}><Box component="img" src={CopyImgIcon} /></Button>
                    <Popper style={{ zIndex: 100000 }} open={openSnackAccountNumber} anchorEl={anchorRef1.current} placement="bottom" transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} style={{ zIndex: 100000 }} >
                          <Paper sx={{ backgroundColor: 'black', color: 'white', padding: '5px', zIndex: '100000' }}>
                            <Typography variant="body1">Copied to clipboard</Typography>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </Box>

                </Box>
                <Box sx={{ display: 'flex', gap: '40px' }}>
                  <BankIcon />
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '23px', letterSpacing: '2%', color: '#8A8C8F' }}>Bank Name</Typography>
                    <Typography variant='h3'>DBS Bank</Typography>
                  </Box>
                </Box>

                <Box sx={{ display: 'flex', gap: '40px', width: '100%', justifyContent: 'space-between' }}>

                  <Box sx={{ display: 'flex', gap: '40px' }}>
                    <BankIcon />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', flex: 1 }}>
                      <Typography sx={{ fontWeight: '500', fontSize: '16px', lineHeight: '23px', letterSpacing: '2%', color: '#8A8C8F' }}>Transaction Reference Code</Typography>
                      <Typography variant='h3'>{referenceCode}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: 'flex', }}>
                    <Button ref={anchorRef2} onClick={copyToClipboard2}><Box component="img" src={CopyImgIcon} /></Button>
                    <Popper style={{ zIndex: 100000 }} open={openSnackReferenceNumber} anchorEl={anchorRef2.current} placement="bottom" transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} style={{ zIndex: 100000 }} >
                          <Paper sx={{ backgroundColor: 'black', color: 'white', padding: '5px', zIndex: '100000' }}>
                            <Typography variant="body1">Copied to clipboard</Typography>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </Box>

                </Box>
              </Box>
              <Typography mt={3} variant='h4' color='Grey'>Reference code for the user to put into his bank transfer details</Typography>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openWallet}
        onClose={handleCloseWallet}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '90%', }}>
              <Typography variant='h1_A' mr={5}>TradeTogether Wallet Info</Typography>
              <Button onClick={handleCloseWallet} sx={{ position: 'absolute', top: '15px', right: '10px', width: '100px' }}><ModalCloseIcon style={{}} /></Button>

              <Box mt={3} sx={{ display: 'flex', flexDirection: 'column', borderRadius: '15px', width: '100%', backgroundColor: '#EAF4FF66' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', mt: 3 }}>
                  <QRcodeIcon />
                  <Typography sx={{ fontWeight: 500, fontSize: '18px', color: '#000000', lineHeight: '30px' }}>Scan QR Code</Typography>
                </Box>
              </Box>

              <Box mt={3} py={1} sx={{ width: '100%', borderRadius: '18px', display: 'flex', backgroundColor: ' #EAF4FF66', flexDirection: 'column' }}>
                {/* <Box component='img' src={localUserData?.picture} sx={{width:'100px', height:'100px', borderRadius:'50%'}}/> */}
                <Box px={2} sx={{ display: 'flex', flexDirection: 'column', gap: '5px', justifyContent: 'center' }}>
                  <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#1F3048', lineHeight: '29px' }}>Wallet address</Typography>
                </Box>

                <Box px={2} sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', }}>
                  <Typography sx={{ fontWeight: 400, fontSize: '18px', color: '#000000', lineHeight: '30px' }}>{walletAddress}</Typography>
                  <IconButton ref={anchorRef3} onClick={copyToClipboard3}><Box component="img" src={CopyIcon} sx={{ width: '18px', height: '20px' }} /></IconButton>
                  <Popper style={{ zIndex: 100000 }} open={openWalletInfo} anchorEl={anchorRef3.current} placement="bottom" transition>
                    {({ TransitionProps }) => (
                      <Fade {...TransitionProps} style={{ zIndex: 100000 }} >
                        <Paper sx={{ backgroundColor: 'black', color: 'white', padding: '5px', zIndex: 100000 }}>
                          <Typography variant="body1">Copied to clipboard</Typography>
                        </Paper>
                      </Fade>
                    )}
                  </Popper>
                </Box>

              </Box>

            </Box>
          </Box>
        </Box>
      </Modal>
    </React.Fragment >
  );
}


export default function ChooseYourMethod() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Button onClick={handleOpen} endIcon={<MdAdd />} sx={{
        width: '140px', fontSize: '14px', textTransform: 'none', color: 'white', backgroundColor: '#3F7DBC', borderRadius: '8px', '&:hover': {
          backgroundColor: '#3F7DBC'
        }
      }}>Add Deposit</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, display: 'flex', }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '150px', width: '80%', }}>
              <Typography variant='h1_A' mr={5}>Choose Your Method</Typography>
              <Button onClick={handleClose} sx={{ position: 'absolute', top: '15px', right: '10px', width: '100px' }}><ModalCloseIcon style={{}} /></Button>

              <ChildModal />
              <Typography variant='h4' color='Grey'>Choose the company’s payment method that is suitable for you.</Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
