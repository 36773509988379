import React, { useEffect, useState } from 'react'

import axios from 'axios'
import Dashboard_newsUpdate from '../a_component/Dashboard_newsUpdate'
import Dashboard_newsAdd from '../a_component/Dashboard_newsAdd'

import Input from '@mui/material/Input';

// import n1 from "../img/dashboard/news1.jpg"

import { Button, Paper, Modal, TextField, Stack, Box, TableBody, TableCell, TableRow, TableHead, Table, TableContainer, Typography } from '@mui/material';

export default function () {

    const [getnewsdata, setgetnewsdata] = useState([])


    const [showModal, setShowModal] = useState(false);

    const [selected, setselected] = useState({ id: "", image: "", title: "", description: "" })

    const [updateupload, setupdateupload] = useState(false)

    const [error, seterror] = useState(false)


    const token = localStorage.getItem("Admintoken")
    const headers = {
        Authorization: `Bearer ${token}`,

    };

    //get all news data

    const getallnewsdata = async () => {

        await axios.get("http://localhost:9672/api/v1/news", { headers })
            .then(res => {
                setgetnewsdata(res.data.data)
            })
            .catch(err => setgetnewsdata([]))
    }




    useEffect(() => {


        getallnewsdata()


    }, [])


    //delete news

    const deletenews = async (id) => {
        try {
            await axios.delete(`http://localhost:9672/api/v1/news/${id}`, { headers })

            setgetnewsdata(getnewsdata.filter(itm => itm.id !== id))

        } catch (error) {
            console.log(error.message);
        }
    }






    const handleupdateImageChange = (event) => {
        const selectedImage = event.target.files[0];
        setselected({
            ...selected,
            image: selectedImage
        })

        if (selectedImage instanceof File) {
            setupdateupload(true)
            seterror(false)
        }

    };



    const handleupdateChange = (event) => {
        const { name, value } = event.target
        setselected({
            ...selected,
            [name]: value
        })
    };



    function handleModalOpen(id) {
        setupdateupload(false)
        setShowModal(true);

        const result = getnewsdata.find(itm => itm.id === id)


        setselected({
            ...selected,
            id: id,
            image: "",
            title: result.title,
            description: result.description,
        })

    };



    const handleModalClose = () => {
        setShowModal(false);
    };




    //update news



    const handleModalSubmit = async () => {


        if (!(selected.image instanceof File)) {
            setupdateupload(false)
            seterror(false)
            seterror(true)
        }



        else {

            const id = selected.id


            try {

                const formData = new FormData();
                formData.append('image', selected.image);
                formData.append('title', selected.title);
                formData.append('description', selected.description);




                await axios.put(`http://localhost:9672/api/v1/news/${id}`, formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` }, })

                setShowModal(false);
                getallnewsdata();
            } catch (error) {
                console.log(error.message);
            }
        }


    };
    console.log('gtetall ', getnewsdata);


    return (

        <Box sx={{ width: "100%" }}>
            {/* <Typography variant="h4" my={2}> News </Typography> */}

            <Dashboard_newsAdd getallnewsdata={getallnewsdata} />

            <Box sx={{
                padding: "20px 20px 20px 20px",
                // boxShadow: "0 0 10px 0 rgba(0,0,0,0.2)"
            }}>
                <Typography variant="h4" my={2}> News List</Typography>

                <TableContainer component={Paper} sx={{ borderRadius: '10px', border: 'none', boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)" }}>
                    <Table sx={{ minWidth: 650, }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">id</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Image</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Title</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Description</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Edit</TableCell>
                                <TableCell sx={{ fontSize: "1.1rem" }} align="center">Delete</TableCell>
                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {getnewsdata.map((itm) => {


                                return (


                                    <TableRow key={itm.id}>
                                        <TableCell fontSize="1.2rem" align="center">{itm.id}</TableCell>
                                        <TableCell align="center"><img src={`http://localhost:9672/api/v1/images/${itm.image_data}`} alt="Image" style={{ objectFit: "contain", maxWidth: '80px', maxHeight: '80px', minWidth: '80px', minHeight: '80px' }} /></TableCell>

                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.title}</TableCell>
                                        <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.description}</TableCell>
                                        <TableCell align="center">



                                            <Button variant="contained" color="warning" onClick={() => { handleModalOpen(itm.id) }}>
                                                Edit
                                            </Button>




                                            <Modal
                                                open={showModal}
                                                onClose={handleModalClose}
                                            // aria-labelledby="modal-title"
                                            // aria-describedby="modal-description"
                                            >
                                                <Box sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    backgroundColor: 'white',
                                                    padding: '20px',
                                                    borderRadius: '8px',
                                                    minWidth: '300px'
                                                }}>
                                                    <h2 id="modal-title ">Edit</h2>


                                                    <Button
                                                        variant="contained"
                                                        component="label"
                                                        sx={{ marginBottom: '20px', marginTop: '20px' }}
                                                    >
                                                        {updateupload ? "uploaded" : "upload image"}

                                                        <Input
                                                            type="file"
                                                            name="image"
                                                            // value={selected.image}
                                                            onChange={handleupdateImageChange}
                                                            hidden
                                                            accept="image/*"
                                                            required

                                                        />

                                                    </Button>
                                                    {error && <p style={{ color: 'red' }}>Image should be uploaded</p>}

                                                    <TextField sx={{ marginBottom: '20px' }}
                                                        label="Title"
                                                        variant="outlined"
                                                        name="title"
                                                        value={selected.title}
                                                        onChange={handleupdateChange}
                                                        fullWidth
                                                    />
                                                    <TextField sx={{ marginBottom: '20px' }}
                                                        label="Description"
                                                        variant="outlined"
                                                        name="description"
                                                        value={selected.description}
                                                        onChange={handleupdateChange}
                                                        fullWidth
                                                    />
                                                    <Stack direction="row" justifyContent="space-between">
                                                        <Button variant="contained" color="error" onClick={handleModalClose}>
                                                            Close
                                                        </Button>
                                                        <Button variant="contained" color="success" onClick={handleModalSubmit}>
                                                            Save Changes
                                                        </Button>

                                                    </Stack>
                                                </Box>
                                            </Modal>







                                        </TableCell>
                                        <TableCell align="center">
                                            <Button variant="contained" color="error" onClick={() => { deletenews(itm.id) }}>Delete</Button>
                                        </TableCell>
                                    </TableRow>

                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box >
    )
}
