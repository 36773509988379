import React from 'react'
import MessageIcon from '../../img/MessageIcon.png';
import { Box, Typography, IconButton, Stack, Pagination, PaginationItem } from '@mui/material';
import { ReactComponent as MarkAsReadIcon } from '../../img/markAsReadIcon.svg';
import { ReactComponent as MarkAsUnread } from '../../img/markAsUnread.svg';
import { ReactComponent as MarkAsReadDisable } from '../../img/markAsReadDisable.svg';
import { ReactComponent as MarkAsUnreadEnable } from '../../img/markAsUnreadEnable.svg';
import { ReactComponent as RemoveNotifitcationIcon } from '../../img/removeNotifitcationIcon.svg';

import { ReactComponent as PaginationForwardIcon } from '../../img/PaginationForwardIcon.svg';
import { ReactComponent as PaginationBackArrowIcon } from '../../img/PaginationBackArrow.svg';
import crd1 from "../../img/news/crd1.jpeg";
import crd2 from "../../img/news/crd2.jpeg";
import crd3 from "../../img/news/crd3.webp";
import crd4 from "../../img/news/crd4.jpeg";
import crd5 from "../../img/news/crd5.jpeg";

export default function General() {
    const generalData = [
        { id: 1, icon: crd1, boldText: 'This the heading of any news that is being shown here change later.', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 2, icon: crd2, boldText: 'This the heading of any news that is being shown here change later.', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 3, icon: crd3, boldText: 'This the heading of any news that is being shown here change later.', time: '1 mins ago', type: 'New', markAsRead: true, markAsUnRead: false },
        { id: 4, icon: crd4, boldText: 'This the heading of any news that is being shown here change later.', time: '1 mins ago', type: 'New', markAsRead: false, markAsUnRead: true },
        { id: 5, icon: crd5, boldText: 'This the heading of any news that is being shown here change later.', time: '1 mins ago', type: 'New', markAsRead: false, markAsUnRead: true },
    ]
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {generalData?.map((item, index) => (
                <Box sx={{ backgroundColor: 'white', borderRadius: index == generalData?.length - 1 ? '0 0 20px 20px' : '0px', paddingBottom: index == generalData?.length - 1 ? ' 30px' : '' }}>
                    <Box key={item.id} sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', borderLeft: item?.markAsRead ? '8px solid #50AD20' : '10px solid #CACACA', paddingLeft: '34px', margin: '34px 34px 34px 0', gap: '30px' }}>
                        <Box component='img' sx={{ width: { xs: '170px', md: '125px' }, height: { xs: '126px', md: '106px' }, borderRadius: "20px", objectFit: 'cover' }} src={item.icon} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <Box sx={{ display: 'flex', gap: '5px' }}>
                                <Typography variant="h3" color={item?.markAsRead ? '' : 'Title_Text_color'} sx={{ letterSpacing: '.7px' }} >{item.boldText}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '30px', alignItems: 'center', gap: '70px' }}>
                                <Typography sx={{ color: '#8A8C8F', fontWeight: '500', letterSpacing: '.3px', fontSize: '14px', lineHeight: '18px' }}>{item.time}</Typography>
                                <Typography sx={{ color: '#50AD20', fontWeight: '500', letterSpacing: '.3px', fontSize: '12px', lineHeight: '15px', padding: '5px', backgroundColor: '#DBEFCD', borderRadius: '5px' }}>{item.type}</Typography>
                            </Box>
                            <Stack direction={{ xs: 'column', md: 'row' }} columnGap={5} rowGap={2.2} mt={1}>
                                <Stack direction='row'>
                                    {item?.markAsRead ? <MarkAsReadIcon /> : <MarkAsReadDisable />}
                                    <Typography variant="h5_2" color={item?.markAsRead ? 'primary' : 'Title_Text_color'} sx={{ ml: 1 }}>Mark as Read</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    {item?.markAsUnRead ? <MarkAsUnreadEnable /> : <MarkAsUnread />}
                                    <Typography variant="h5_2" color={item?.markAsUnRead ? 'primary' : 'Title_Text_color'} sx={{ ml: 1 }}>Mark as Unread</Typography>
                                </Stack>
                                <Stack direction='row'>
                                    <RemoveNotifitcationIcon />
                                    <Typography variant="h5_2" color='Title_Text_color' sx={{ ml: 1 }}>Remove Notification</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            ))
            }

            <Stack mt={4} direction='row' justifyContent='center' spacing={2}>
                <Pagination
                    count={5}
                    sx={{ '.MuiPaginationItem-circular': { backgroundColor: 'white' }, '.Mui-selected': { backgroundColor: '#3F7DBC !important', color: 'white' }, '.Mui-selected:hover': { backgroundColor: '#3F7DBC', color: 'white' } }}
                    renderItem={(item) => (
                        <PaginationItem slots={{ previous: PaginationBackArrowIcon, next: PaginationForwardIcon }}  {...item}
                        />)}
                />
            </Stack>
        </Box >
    )
}
