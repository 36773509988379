import React, { useState } from 'react'
import {Box, Typography, Button, IconButton} from "@mui/material";
import { ReactComponent as EditIcon } from '../../img/edit_pencil.svg';
import { ReactComponent as AvatarIcon } from '../../img/avatar_temp.svg';

export default function UserProfileInfo ()  {
    return(
        <Box sx={{ margin:'10px', borderRadius:'18px', padding:'20px', display:'flex', gap:'5px',  backgroundColor:' #EAF4FF66', justifyContent:'space-between'}}>
            <Box sx={{display:'flex',gap:'20px'}}>
            <AvatarIcon style={{width:'100px', height:'100px'}}/>
            <Box sx={{display:'flex', flexDirection:'column', gap:'5px', justifyContent:'center'}}>
                <Typography sx={{fontWeight:500, fontSize:'22px',  lineHeight:'37px'}}>John Doe</Typography>
                <Typography sx={{fontWeight:500, fontSize:'18px',color:'#8A8C8F', letterSpacing:'5%'}}>Premium Member</Typography>
            </Box>
            </Box>
            <Box sx={{display:'flex', alignSelf:'center', borderRadius:'30px', background:'white', gap:'5px', height:'35px', padding:'0 10px' }}>
            <Typography sx={{fontWeight:500, fontSize:'16px',color:'#8A8C8F', letterSpacing:'5%', lineHeight:'25px', alignSelf:'center'}}>Edit</Typography>
            <IconButton><EditIcon /></IconButton>
            </Box>
      </Box>
    )
}