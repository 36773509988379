import React, { useState, useEffect, } from 'react'

import bcrypt from 'bcryptjs';

import { useNavigate } from 'react-router-dom';

import { Button, Paper, Modal, TextField, Stack, styled, Box, TableBody, TableCell, TableRow, TableHead, Table, TableContainer, Typography } from '@mui/material';

import axios from 'axios';

import Bg from "../images/bg2.jpg"
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';




const StyledErrorText = styled(Typography)({
    color: '#ff0000',
    fontSize: "0.85rem",
    margin: "4px 0"
})


export default function Login() {


    const navigate = useNavigate()


    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [logindata, setlogindata] = useState({ email: "", password: "" })

    const [invalid, setinvalid] = useState({ email: "", password: "" })
    const [incorrect, setincorrect] = useState({ email: "", password: "" })

    const handlechange = (e) => {
        const { name, value } = e.target;

        setlogindata(prev => {
            return ({
                ...prev,
                [name]: value
            })
        })


    }

    const emailregex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/





    const onSubmitForm = (e) => {

        e.preventDefault();


        const email = logindata.email
        const password = logindata.password

        // const hashedPassword = bcrypt.hashSync(password, 10);

        // console.log(hashedPassword);
        // console.log(bcrypt.compare(password, user.password));


        if (!(emailregex.test(email))) {
            setinvalid((prev) => { return ({ ...prev, email: true }) })
            setincorrect((prev) => { return ({ email: "", password: "" }) })

        }
        else if ((password.length < 10)) {
            setinvalid((prev) => { return ({ email: "", password: true }) })
            setincorrect((prev) => { return ({ email: "", password: "" }) })
        }

        else (

            axios.post(`http://localhost:9672/api/v1/adminlogin/${1}`, {
                "email": email, "password": password
            })
                .then((response) => {

                    const data = response.data.data;

                    console.log(response);
                    if (data === "erremail") {
                        setincorrect((prev) => { return ({ ...prev, email: true }) })
                        setinvalid((prev) => { return ({ email: "", password: "" }) })
                    } else if (data === "errpassword") {
                        setincorrect((prev) => { return ({ email: "", password: true }) })
                        setinvalid((prev) => { return ({ email: "", password: "" }) })
                    }
                    else if ((data !== "erremail") && (data !== "errpassword")) {
                        if (!(data === null)) {

                            localStorage.setItem("Admintoken", data)
                            navigate("/admin")
                        }

                    }

                    // 


                    // console.log(!!localStorage.getItem("Admintoken"))



                    // Store the token in the browser's local storage
                    // localStorage.setItem('token', token);
                    // Redirect to admin dashboard or protected route
                    // Replace "/admin/dashboard" with your desired protected route
                    // window.location.href = '/admin/dashboard';
                })
                .catch((error) => {
                    console.log(error.message)
                })
        )


    }

    useEffect(() => {
        // console.log("ljgoiy")
        localStorage.removeItem("Admintoken")

    }, [])




    return (

        <Box width="100%" height="100vh"
            sx={{
                display: "flex",
                justifyContent: 'center',
                alignItems: "center",
                backgroundImage: `url('${Bg}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',



            }}>

            <Box component="form" onSubmit={onSubmitForm} sx={{
                minWidth: "400px",
                height: "auto",
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
                backgroundColor: "white",
                opacity: 1
            }} >
                <Typography variant="h4" textAlign="center"
                    sx={{ marginBottom: "20px" }}
                >Admin Login</Typography>

                <TextField
                    autoComplete="off"
                    label="Email"
                    name="email"
                    onChange={handlechange}
                    required
                    fullWidth
                    error={Boolean(invalid.email || incorrect.email)}
                />
                {(invalid.email) && <StyledErrorText>Invalid email</StyledErrorText>}
                {(incorrect.email) && <StyledErrorText>Incorrect email</StyledErrorText>}

                <FormControl sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }} variant="outlined" >
                    <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                    <OutlinedInput
                        name="password"
                        onChange={handlechange}
                        id="outlined-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password"
                        error={Boolean(invalid.password || incorrect.password)}
                        required

                    />
                    {(invalid.password) && <StyledErrorText>Invalid password</StyledErrorText>}
                    {(incorrect.password) && <StyledErrorText>Incorrect password</StyledErrorText>}
                </FormControl>
                <Typography variant="h5" textAlign="end" color="#282e99">Forgot Password</Typography>
                <Stack alignItems="center">
                    <Button type="submit" variant='contained'
                        sx={{
                            backgroundColor: "#080c67",
                            color: "white"
                        }}
                    >Login</Button>
                </Stack>
            </Box>
        </Box >
    )
}
