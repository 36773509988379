import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import General from './General';
import News from "./News";
import Events from "./Events";
import Email from "./Email"

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box backgroundColor='Bg_color'>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Messages() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const TabWithCount = ({ children, count, index }) => {
    return (
      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
        <Typography component="div" variant="h3">{children}</Typography>
        {count && (value == index) ? (
          <Typography
            component="div"
            ml={1}
            sx={{ width: '21px', height: '21px', backgroundColor: '#50AD20', color: 'white', fontSize: '15px', borderRadius: '5px', textAlign: 'center' }}
          >
            {count}
          </Typography>
        ) : <Typography
          component="div"
          ml={1}
          sx={{ width: '21px', height: '21px', }}
        >
        </Typography>}
      </Box>
    );
  };


  return (

    <Box backgroundColor='#F0F7FD' height='auto' pb='70px'>
      <Box sx={{
        backgroundColor: 'white',
        // margin: {
        //   sm: '35px 25px 10px 25px ',
        //   xs: '35px 5px 10px 5px '
        // },
        // padding:0,
        borderTopLeftRadius: "18px",
        borderTopRightRadius: '18px',

      }}>
        <Box sx={{ width: '100%' }}>
          <Box px={3} sx={{ borderBottom: 2, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" sx={{ '& .MuiTabs-indicator': { height: '0 !important' }, '& .MuiTabs-scroller': {overflow:'auto !important'} }}>
              <Tab sx={{ minWidth: 'auto', height: '72px', paddingTop:'14px', color: '#1F3048', fontSize: '18px', lineHeight: '30px', borderBottom: "4px solid #FFFFFF", fontWeight: '500', textTransform: 'none', '&.Mui-selected': { color: '#50AD20', borderBottom: "4px solid #50AD20" }, '& .MuiTab-indicator': { color: '#50AD20', } }} label={<TabWithCount index={0} count="5">General</TabWithCount>} {...a11yProps(0)} />
              <Tab sx={{ minWidth: 'auto', height: '72px', paddingTop:'14px', color: '#1F3048', fontSize: '18px', lineHeight: '30px', borderBottom: "4px solid #FFFFFF", fontWeight: '500', textTransform: 'none', '&.Mui-selected': { color: '#50AD20', borderBottom: "4px solid #50AD20" }, '& .MuiTab-indicator': { color: '#50AD20', } }} label={<TabWithCount index={1} count="3">News</TabWithCount>} {...a11yProps(1)} />
              <Tab sx={{ minWidth: 'auto', height: '72px', paddingTop:'14px', color: '#1F3048', fontSize: '18px', lineHeight: '30px', borderBottom: "4px solid #FFFFFF", fontWeight: '500', textTransform: 'none', '&.Mui-selected': { color: '#50AD20', borderBottom: "4px solid #50AD20" }, '& .MuiTab-indicator': { color: '#50AD20', } }} label={<TabWithCount index={2} count="4">Events</TabWithCount>} {...a11yProps(2)} />
              <Tab sx={{ minWidth: 'auto', height: '72px', paddingTop:'14px', color: '#1F3048', fontSize: '18px', lineHeight: '30px', borderBottom: "4px solid #FFFFFF", fontWeight: '500', textTransform: 'none', '&.Mui-selected': { color: '#50AD20', borderBottom: "4px solid #50AD20" }, '& .MuiTab-indicator': { color: '#50AD20', } }} label={<TabWithCount index={3} count="1">Email</TabWithCount>} {...a11yProps(3)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <General />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <News />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Events />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Email />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
}