import * as React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import './Dashboard.css';
import Cookies from 'js-cookie';
import { useAuth0 } from '@auth0/auth0-react';
import SideBar from '../components/SideBarNew';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SetSnackBar } from '../redux/Global/GlobalAction';


export default function Dashboard() {
  const {
    logout,
    user,
    isAuthenticated,
    loginWithRedirect,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(user?.sub);

  const [sidebaropen, setsidebarOpen] = React.useState(true);
  const toggleDrawer = () => {
    setsidebarOpen(!sidebaropen);
  };

  // console.log('Access TOken', getAccessTokenSilently());

  function setCookie(name, value, options = {}) {
    let cookieStr = `${name}=${value}`;

    // Set additional options (optional)
    const { expires, path, domain, secure } = options;
    if (expires) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + expires);
      cookieStr += `; expires=${expirationDate.toUTCString()}`;
    }
    if (path) {
      cookieStr += `; path=${path}`;
    }
    if (domain) {
      cookieStr += `; domain=${domain}`;
    }
    if (secure) {
      cookieStr += `; secure`; // Only send over HTTPS (less secure)
    }

    document.cookie = cookieStr;
  }

  // Usage
  const token = 'your_token_here';
  setCookie('token', token, { expires: 7, secure: true });

  useEffect(() => {
    console.log('Authentic user', isAuthenticated, user);
    if (isAuthenticated && user) {
      // console.log("authenciated details",getIdTokenClaims())
      // getIdTokenClaims().then(res => {
      //   console.log("idTokenclaims",res);
      //   if(res.__raw){
      //     localStorage.setItem('token', res.__raw);
      //   }
      // });
      getAccessTokenSilently().then((res) => {
        console.log('access:::', res);
        localStorage.setItem('token', res);
        Cookies.set('token', res, { expires: 7, secure: true, httpOnly: true });
        setCookie('token', res, { expires: 7, secure: true })
      });
      console.log(localStorage.getItem('userData'));
      if (localStorage.getItem('userData') === null) {
        dispatch(SetSnackBar('success', 'Logged in Successfully!'));
        localStorage.setItem('userData', JSON.stringify(user));
        let data = JSON.parse(localStorage.getItem('userData'));
        console.log(data);
      }
    }
  }, [isAuthenticated, user]);


  return (
    <SideBar>
      <Outlet />
    </SideBar>
  );
}
