// import React, { useState } from 'react'


// import AccountCircle from '@mui/icons-material/AccountCircle';



// import { styled, useTheme } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
// import ListItem from '@mui/material/ListItem';
// import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import MailIcon from '@mui/icons-material/Mail';


// import AddIcon from '@mui/icons-material/Add';
// import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

// const drawerWidth = 240;

// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//     ({ theme, open }) => ({
//         flexGrow: 1,
//         padding: theme.spacing(3),
//         transition: theme.transitions.create('margin', {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         marginLeft: `-${drawerWidth}px`,
//         ...(open && {
//             transition: theme.transitions.create('margin', {
//                 easing: theme.transitions.easing.easeOut,
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//             marginLeft: 0,
//         }),
//     }),
// );

// const AppBar = styled(MuiAppBar, {
//     shouldForwardProp: (prop) => prop !== 'open',
// })(({ theme, open }) => ({
//     transition: theme.transitions.create(['margin', 'width'], {
//         easing: theme.transitions.easing.sharp,
//         duration: theme.transitions.duration.leavingScreen,
//     }),
//     ...(open && {
//         width: `calc(100% - ${drawerWidth}px)`,
//         marginLeft: `${drawerWidth}px`,
//         transition: theme.transitions.create(['margin', 'width'], {
//             easing: theme.transitions.easing.easeOut,
//             duration: theme.transitions.duration.enteringScreen,
//         }),
//     }),
// }));

// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',
// }));

// export default function PersistentDrawerLeft() {










//     const theme = useTheme();
//     const [open, setOpen] = React.useState(true);

//     const handleDrawerOpen = () => {
//         setOpen(true);
//     };

//     const handleDrawerClose = () => {
//         setOpen(false);
//     };

//     return (
//         <Box sx={{ backgroundColor: "#F0F7FD", display: 'flex', height: "100vh" }}>
//             <CssBaseline />
//             <AppBar position="fixed" open={open}>
//                 <Toolbar>
//                     <IconButton
//                         color="inherit"
//                         aria-label="open drawer"
//                         onClick={handleDrawerOpen}
//                         edge="start"
//                         sx={{ mr: 2, ...(open && { display: 'none' }) }}
//                     >
//                         <MenuIcon sx={{ color: "#3F7DBC", fontSize: "1.7rem" }} />
//                     </IconButton>
//                     <Box component="div" sx={{ flexGrow: 1 }}>
//                         <IconButton sx={{ height: '70px', }}>
//                             <img src={Logo} height="100%" />
//                         </IconButton>
//                     </Box>
//                     <IconButton sx={{
//                         backgroundColor: "#F0F7FD",
//                         width: "40px",
//                         height: "40px",
//                         borderRadius: "100px"
//                     }}
//                         aria-controls="menu-appbar"
//                         onClick={handleMenu}
//                     >
//                         {/* <AccountCircle /> */}
//                         <Typography variant='h6' fontSize="1.5rem" color="#93B0C8">S</Typography>
//                     </IconButton>
//                     <Menu
//                         id="menu-appbar"
//                         anchorEl={anchorEl}
//                         anchorOrigin={{
//                             vertical: 'top',
//                             horizontal: 'right',
//                         }}
//                         keepMounted
//                         transformOrigin={{
//                             vertical: 'top',
//                             horizontal: 'right',
//                         }}
//                         open={Boolean(anchorEl)}
//                         // open={true}
//                         onClose={handleClose}
//                     >
//                         <MenuItem onClick={handleClose}>Logout</MenuItem>

//                     </Menu>
//                 </Toolbar>
//             </AppBar >
//             <Drawer
//                 sx={{
//                     width: drawerWidth,

//                     flexShrink: 0,
//                     '& .MuiDrawer-paper': {
//                         width: drawerWidth,
//                         boxSizing: 'border-box',
//                     },
//                 }}
//                 variant="persistent"
//                 anchor="left"
//                 open={open}
//             >
//                 <DrawerHeader sx={{ backgroundColor: "" }}>
//                     <IconButton onClick={handleDrawerClose}>
//                         {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//                     </IconButton>
//                 </DrawerHeader>
//                 <Divider />
//                 <List sx={{ backgroundColor: "", height: "100%" }}>

//                     <ListItem>
//                         <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "#F0F7FD", borderRadius: "15px" }}>
//                             <ListItemText >
//                                 <Typography variant='h6' color=""> Member Details</Typography>
//                             </ListItemText>
//                         </ListItemButton>
//                     </ListItem>
//                     <ListItem>
//                         <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "#F0F7FD", borderRadius: "15px" }}>

//                             <ListItemText>
//                                 <Typography variant='h6'> Create New Member</Typography>

//                             </ListItemText>
//                         </ListItemButton>
//                     </ListItem>
//                     <ListItem>
//                         <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "#F0F7FD", borderRadius: "15px" }}>

//                             <ListItemText>
//                                 <Typography variant='h6'>Create Entry</Typography>

//                             </ListItemText>
//                         </ListItemButton>
//                     </ListItem>
//                     <ListItem>
//                         <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "#F0F7FD", borderRadius: "15px" }}>

//                             <ListItemText>
//                                 <Typography variant='h6'>Create Transaction</Typography>

//                             </ListItemText>
//                         </ListItemButton>
//                     </ListItem>
//                     <ListItem>
//                         <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "#F0F7FD", borderRadius: "15px" }}>

//                             <ListItemText>
//                                 <Typography variant='h6'>Create Daily</Typography>

//                             </ListItemText>
//                         </ListItemButton>
//                     </ListItem>
//                     <ListItem>
//                         <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "#F0F7FD", borderRadius: "15px" }}>

//                             <ListItemText>
//                                 <Typography variant='h6'> Update Balance</Typography>

//                             </ListItemText>
//                         </ListItemButton>
//                     </ListItem>
//                     <ListItem>
//                         <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "#F0F7FD", borderRadius: "15px" }}>

//                             <ListItemText>
//                                 <Typography variant='h6'>Update Yield & Allocation</Typography>

//                             </ListItemText>
//                         </ListItemButton>
//                     </ListItem>

//                 </List>

//             </Drawer>
//             <Main open={open} sx={{ height: "90%", width: "100%" }}>
//                 <DrawerHeader />

//                 <Box component="div"
//                     sx={{
//                         // backgroundColor: "#F0F7FD",
//                         height: "100%",
//                         width: "100%",
//                         display: 'flex',
//                         flexDirection: "column",
//                         justifyContent: "center",


//                     }}

//                 >

//                     <Grid container

//                         sx={{
//                             width: "100%",
//                             display: "flex",
//                             flexWrap: "wrap",
//                             justifyContent: "center",
//                             // flexDirection: "row",

//                         }}
//                     >
//                         <Grid my={4} lg={6} md={6} sm={8} xs={12} display="flex" justifyContent="center" >

//                             <ListItem sx={{ minWidth: "340px", maxWidth: "350px", height: "fit-Content" }}>
//                                 <ListItemButton sx={{ color: "#3F7DBC", backgroundColor: "lavender", borderRadius: "15px" }}>
//                                     <ListItemText>
//                                         <Typography variant='h6' textAlign="center" fontSize="1.7rem">

//                                             Create New Member
//                                         </Typography>

//                                     </ListItemText>
//                                 </ListItemButton>
//                             </ListItem>

//                         </Grid>
//                         <Grid lg={6} md={6} sm={8} xs={12} my={4} display="flex" justifyContent="center">
//                             <ListItem sx={{ minWidth: "340px", maxWidth: "350px", height: "fit-Content" }}>
//                                 <ListItemButton sx={{
//                                     color: "white",
//                                     backgroundColor: "#5f9ea0a8", borderRadius: "15px"
//                                 }}>

//                                     <ListItemText>
//                                         <Typography variant='h6' textAlign="center" fontSize="1.7rem">

//                                             Create Profit</Typography>

//                                     </ListItemText>
//                                 </ListItemButton>
//                             </ListItem>
//                         </Grid>
//                         <Grid lg={6} md={6} sm={8} xs={12}  my={4} display="flex" justifyContent="center">
//                             <ListItem sx={{ minWidth: "340px", maxWidth: "350px", height: "fit-Content" }}>
//                                 <ListItemButton sx={{ color: "white", backgroundColor: "#008b8b4a", borderRadius: "15px" }}>

//                                     <ListItemText>
//                                         <Typography variant='h6' textAlign="center" fontSize="1.7rem"> Create Transaction</Typography>

//                                     </ListItemText>
//                                 </ListItemButton>
//                             </ListItem>
//                         </Grid>

//                     </Grid>

//                 </Box>

//             </Main >

//         </Box >
//     );
// }



import React, { useState, useEffect } from 'react';

import { Link, Outlet, useNavigate } from "react-router-dom";


import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PersonIcon from '@mui/icons-material/Person';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';


import { Collapse, Icon, Menu, MenuItem, Button, Stack, Grid } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';

import { AiFillAppstore, AiOutlineDollarCircle } from 'react-icons/ai';
import { PiNotepad } from 'react-icons/pi';
import { TbUsers } from 'react-icons/tb';
import { BiSolidCoinStack } from "react-icons/bi"
import { SiDatocms } from "react-icons/si"


import Logo from "../images/logo.svg"


const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function MiniDrawer() {


    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState(null);

    const [cmsopen, setsetcmsOpen] = useState(false);

    const handleMenu = (event) => {

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        navigate("/adminlogin")

        setAnchorEl(null);
    };





    const theme = useTheme();
    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };



    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box component="div" sx={{ flexGrow: 1 }}>
                        <IconButton sx={{ height: '70px', }}>
                            <img src={Logo} height="100%" />
                        </IconButton>
                    </Box>
                    <IconButton sx={{
                        backgroundColor: "#F0F7FD",
                        width: "40px",
                        height: "40px",
                        borderRadius: "100px"
                    }}
                        aria-controls="menu-appbar"
                        onClick={handleMenu}
                    >
                        {/* <AccountCircle /> */}
                        <Typography variant='h6' fontSize="1.5rem" color="#93B0C8">S</Typography>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        // open={true}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>Logout</MenuItem>

                    </Menu>
                </Toolbar>
            </AppBar >
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 55,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <AiFillAppstore fontSize="1.4rem" />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                                Dashboard
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <Link to="/admin/customer">
                        <ListItem disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 55,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText sx={{ color: "black", opacity: open ? 1 : 0 }} >
                                    Customer
                                </ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </Link>

                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 55,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <BiSolidCoinStack fontSize="1.3rem" />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                                Product
                            </ListItemText>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 55,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                            }}
                            onClick={() => { setsetcmsOpen(!cmsopen) }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    ml: "2px",
                                    justifyContent: 'center',
                                }}
                            >
                                <SiDatocms fontSize="1.1rem" />
                            </ListItemIcon>
                            <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                                CMS
                            </ListItemText>

                            {open && (cmsopen ? <ExpandLess /> : <ExpandMore />)}

                        </ListItemButton>
                        {open &&
                            <Collapse in={cmsopen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to="/admin/Dashboardupcomingevents">
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <TbUsers fontSize="1.3rem" />
                                            </ListItemIcon>
                                            <ListItemText sx={{ color: "black" }} primary="Events" />
                                        </ListItemButton>
                                    </Link>
                                    <Link to="/admin/dashboard_news">
                                        <ListItemButton sx={{ pl: 4 }}>
                                            <ListItemIcon>
                                                <PiNotepad fontSize="1.4rem" />
                                            </ListItemIcon>
                                            <ListItemText sx={{ color: "black" }} primary="News" />
                                        </ListItemButton>
                                    </Link>
                                </List>
                            </Collapse>
                        }
                    </ListItem>

                </List>

            </Drawer>
            <Box component="main" width="100%" >
                <DrawerHeader />
                <Outlet />
            </Box>
        </Box >
    );
}
