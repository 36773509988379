import React, { useEffect, useState } from 'react';



import { Button, Paper, Modal, TextField, Stack, styled, Box, TableBody, TableCell, TableRow, TableHead, Table, TableContainer, Typography } from '@mui/material';
import axios from 'axios';



export default function Admin_customer() {


    const [customers, setcustomers] = useState([])

    const getcustomerdata = async (req, res) => {

        try {


            const token = localStorage.getItem("Admintoken")
            const headers = {
                Authorization: `Bearer ${token}`, 
               
            };

            const response = await axios.get("http://localhost:9672/api/v1/customers",{headers})

          
            setcustomers(response.data.data)
          

        } catch (error) {
            console.log(error.message)
        }
    }

    
    useEffect(() => {
        getcustomerdata();


    }, [])



    return (

        <Box mt={4} p={1} sx={{ width: "100%", height: "auto", backgroundColor: "" }}>

            <Typography mb={5} variant='h4'>Customers</Typography>
            <TableContainer component={Paper} sx={{ borderRadius: '10px', border: 'none', boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)" }}>
                <Table sx={{ minWidth: 650, }} aria-label="simple table">
                    <TableHead>
                        <TableRow >
                            <TableCell sx={{ fontSize: "1.1rem" }} align="center">Id</TableCell>
                            <TableCell sx={{ fontSize: "1.1rem" }} align="center">First Name</TableCell>
                            <TableCell sx={{ fontSize: "1.1rem" }} align="center">Last Name</TableCell>
                            <TableCell sx={{ fontSize: "1.1rem" }} align="center">DOB</TableCell>
                            <TableCell sx={{ fontSize: "1.1rem" }} align="center">Mobile Number</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {customers.map(itm => {
                            return (
                                <TableRow>
                                    <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.id}</TableCell>
                                    <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.first_name}</TableCell>
                                    <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.last_name}</TableCell>
                                    <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.dob}</TableCell>
                                    <TableCell sx={{ fontSize: "1.1rem" }} align="center">{itm.mobile_number}</TableCell>
                                </TableRow>
                            )
                        })}


                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}
